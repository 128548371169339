import { AsyncPipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, computed, effect, inject, model, signal } from '@angular/core';

import 'd3-transition';
import { BehaviorSubject, firstValueFrom, map, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import 'd3-transition';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { ButtonModule } from 'primeng/button';

import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { GraphService } from 'src/app/services/graph.service';
import { Article, ArticleNode, PeriodResponse, Publisher, TopicNode } from 'src/app/api/backend-connector/models';
import { RouterLink } from '@angular/router';
import { CustomNode, TOPIC } from 'src/app/utils/graph';
import { SingleChoicePanelComponent } from 'src/app/widgets/single-choice-panel/single-choice-panel.component';
import { IconFieldModule } from 'primeng/iconfield';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { GraphComponent } from 'src/app/widgets/graph/graph.component';
import { NodeDetailsPanelComponent } from 'src/app/components/node-details-panel/node-details-panel.component';
import { RelatedArticlesComponent } from 'src/app/components/related-articles/related-articles.component';
import { RelatedTopicsComponent } from 'src/app/components/related-topics/related-topics.component';
import { PublishersService } from 'src/app/services/publishers.service';
import { StructuredPanelComponent } from 'src/app/widgets/structured-panel/structured-panel.component';
import { ArticlesService } from 'src/app/services/articles.service';

@Component({
  selector: 'app-explore-page',
  templateUrl: './explore-page.component.html',
  styleUrl: './explore-page.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    FormsModule,
    GraphComponent,
    IconFieldModule,
    InputTextModule,
    NgClass,
    NodeDetailsPanelComponent,
    OverlayPanelModule,
    ReactiveFormsModule,
    RelatedArticlesComponent,
    RelatedTopicsComponent,
    RouterLink,
    SingleChoicePanelComponent,
    StructuredPanelComponent,
    SvgIconComponent,
    TimeAgoPipe,
    TranslateModule,
  ],
  providers: [TimeAgoPipe],
})
export class ExplorePageComponent implements AfterViewInit {
  private readonly graphService = inject(GraphService);
  private readonly publishersService = inject(PublishersService);
  private readonly articlesService = inject(ArticlesService);

  readonly selectedNode = model<(CustomNode & { publisherName?: string }) | undefined>(undefined);
  readonly relatedArticles = model<ArticleNode[] | null>(null);
  readonly relatedTopics = model<TopicNode[] | null>(null);
  readonly period = signal<(PeriodResponse & { label: string }) | undefined>(undefined);
  readonly periods$ = new BehaviorSubject<Array<PeriodResponse & { label: string }> | null>(null);
  readonly publishers = model<(Publisher & { color: string; isSelected: boolean })[]>([]);
  readonly publishers$ = this.publishersService.listPublishers({ limit: 30 }).pipe(
    map(({ items }) =>
      items.map((i) => ({
        ...i,
        color: TOPIC,
      })),
    ),
    tap((items) => this.publishers.set(items.map((i) => ({ ...i, isSelected: false })))),
  );

  readonly query = inject(FormBuilder).nonNullable.control('' as string | undefined);
  isPanelOpen = false;

  constructor() {
    effect(() => {
      const node = this.selectedNode();
      if (node) {
        this.isPanelOpen = true;
      }
    });
  }

  readonly relatedTopicsMap = computed(() => {
    const topics = this.relatedTopics() ?? [];
    const nodeMapList: { topic: TopicNode; articles: Article[] }[] = [];
    for (let i = 0; i < topics?.length; i++) {
      void firstValueFrom(this.articlesService.listArticles({ topicIds: [topics[i].id], limit: 5 })).then(
        ({ items }) => {
          nodeMapList.push({ topic: topics[i], articles: items.filter((a) => a.id !== this.selectedNode()?.id) });
        },
      );
    }
    return nodeMapList;
  });

  async ngAfterViewInit(): Promise<void> {
    // TODO: FIXME
    const periods = await firstValueFrom(
      this.graphService.listPeriods().pipe(map(({ items }) => items.map((p) => ({ ...p, label: p.name })))),
    );
    this.periods$.next(periods);
    const p = periods.at(0);
    if (p) {
      this.period.set(p);
    }
  }

  selectPeriod(id?: string) {
    const periods = this.periods$.value;
    const p = periods?.find((p) => p.id === id);
    if (p) {
      this.period.set(p);
    }
  }

  async togglePanel(open: boolean) {
    this.isPanelOpen = open;
    if (!open) {
      this.selectedNode.set(undefined);
    }
  }

  calcSelectedPublishers(items: (Publisher & { color: string; isSelected: boolean })[]) {
    return items.filter(({ isSelected }) => isSelected)?.length;
  }
}
