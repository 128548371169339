import { Injectable, inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Article, ArticleResponsePage } from '../api/backend-connector/models';
import { ArticlesApi } from '../api/backend-connector/services';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  private readonly articlesApi = inject(ArticlesApi);
  readonly refresh$ = new BehaviorSubject<void>(undefined);

  listArticles(params: Parameters<typeof this.articlesApi.listArticles>[0]): Observable<ArticleResponsePage> {
    return this.articlesApi.listArticles(params);
  }

  getArticle(params: Parameters<typeof this.articlesApi.getArticle>[0]): Observable<Article> {
    return this.articlesApi.getArticle(params);
  }

  listRelatedArticles(
    params: Parameters<typeof this.articlesApi.listRelatedArticles>[0],
  ): Observable<ArticleResponsePage> {
    return this.articlesApi.listRelatedArticles(params);
  }
}
