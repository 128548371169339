<div
  #messageContent
  class="relative mr-2 flex w-full flex-row items-center gap-2 rounded-lg text-zinc-900">
  @if ({ wrap: false }; as widgetUI) {
    <div class="message-content max-sm:max-w-xs">
      @if (senderType !== SenderType.User) {
        <div class="flex flex-col">
          <!-- This allocates space to allow auto-scrolling and to have the outline of the message before the content -->
          <span
            class="invisible"
            [innerHTML]="content | sanitizeHtml"></span>

          @if (widgets?.at(0)) {
            <div
              class="invisible w-full"
              [ngStyle]="{ height: '125px' }"></div>
          }

          @if (contentAfterWidgets) {
            <span
              class="invisible"
              [innerHTML]="contentAfterWidgets"></span>
          }

          <div class="absolute top-0 w-full">
            <span
              class="text-content"
              [innerHTML]="content | sanitizeHtml"></span>

            @if ((widgets?.length ?? 0) > 2) {
              <div
                class="widget-title sticky inset-x-0 -top-2 z-50 flex flex-row items-center justify-between text-sm"
                [ngClass]="{
                  'border-b': widgetUI.wrap,
                }">
                @if (expandedWidget) {
                  <div class="flex flex-row items-center">
                    @if (widgetUI.wrap) {
                      <button
                        class="rounded-full p-1 transition-all"
                        [ngClass]="{
                          'pointer-events-none cursor-not-allowed touch-none opacity-40':
                            widgetContainer.scrollLeft === 0,
                        }"
                        (click)="scrollWidgets(widgetContainer, 'prev')">
                        <svg-icon
                          key="arrow-up"
                          class="-rotate-90"></svg-icon>
                      </button>

                      <button
                        class="rounded-full p-1 transition-all"
                        [ngClass]="{
                          'pointer-events-none cursor-not-allowed touch-none opacity-40':
                            widgetContainer.scrollLeft + 250 > widgetContainer.clientWidth,
                        }"
                        (click)="scrollWidgets(widgetContainer, 'next')">
                        <svg-icon
                          key="arrow-up"
                          class="rotate-90"></svg-icon>
                      </button>
                    }

                    <button
                      class="rounded-full p-1 transition-all"
                      (click)="widgetUI.wrap = !widgetUI.wrap">
                      wrap
                    </button>
                  </div>
                }
              </div>
            }

            <div
              #widgetContainer
              class="widget-container flex w-full flex-row items-start gap-x-2 overflow-x-auto mt-2"
              [class.flex-wrap]="widgetUI.wrap"
              [class.overflow-hidden]="expandedWidget">
              @for (widget of widgets; track $index) {
                @switch (widget.widget_type) {
                  @case ('reference') {
                    @if (castReferenceWidget(widget); as referenceWidget) {
                      <app-reference-widget
                        class="flex-1 min-w-60"
                        [widget]="referenceWidget"></app-reference-widget>
                    }
                  }
                  @case ('news') {
                    @if (castNewsWidget(widget); as newsWidget) {
                      <app-news-widget
                        class="flex-1 min-w-60"
                        [widget]="newsWidget"></app-news-widget>
                    }
                  }
                }
              }
            </div>

            <span
              class="text-content"
              [innerHTML]="contentAfterWidgets ?? '' | sanitizeHtml"></span>
          </div>
        </div>
      }

      @if (senderType === SenderType.User) {
        <span>{{ content }}</span>
      }
    </div>
  }
</div>
