import { Publisher } from 'src/app/api/backend-connector/models';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { structuredItem, StructuredPanelComponent } from './../../widgets/structured-panel/structured-panel.component';
import { SimplePanelComponent } from './../../widgets/simple-panel/simple-panel.component';
import { IconFieldModule } from 'primeng/iconfield';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AsyncPipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TopicsService } from './../../services/topics.service';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { WkQueryParamsService } from '../../services/wk-queryparams.service';
import { switchMap, shareReplay, combineLatest, map, Observable } from 'rxjs';
import { InputTextModule } from 'primeng/inputtext';
import { MapVisualizerComponent } from '../../components/map-visualizer/map-visualizer.component';
import { MapPointComponent } from '../../components/map-visualizer/map-point.component';
import { MapPathComponent } from '../../components/map-visualizer/map-path.component';

import * as thumbnails from '../../../assets/thumbnails.json';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { dateToJsDate, jsDateToDate } from 'src/app/utils/date';
import { FormBuilder } from '@angular/forms';
import { qpCast1, qpCastN } from 'src/app/services/queryparams.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { PublishersService } from 'src/app/services/publishers.service';

export const castToKey = <T>(value: string): keyof T => {
  if (value in thumbnails) {
    return value as keyof T;
  }
  throw new Error(`Value ${value} is not a key of the object`);
};

@Component({
  selector: 'app-topics-list-page',
  templateUrl: './topics-list-page.component.html',
  styleUrl: './topics-list-page.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    CalendarModule,
    IconFieldModule,
    InputTextModule,
    MapPathComponent,
    MapPointComponent,
    MapVisualizerComponent,
    OverlayPanelModule,
    PaginatorModule,
    RouterLink,
    SimplePanelComponent,
    SkeletonModule,
    StructuredPanelComponent,
    SvgIconComponent,
    TimeAgoPipe,
    TooltipModule,
    TranslateModule,
  ],
})
export class TopicsListPageComponent {
  private readonly topicsService = inject(TopicsService);
  private readonly publishersService = inject(PublishersService);
  private readonly fb = inject(FormBuilder);

  readonly wkQp = inject(WkQueryParamsService);
  readonly thumbnails = thumbnails;
  readonly publisherNames = Object.keys(thumbnails);
  readonly castToKey = castToKey;
  readonly dateToJsDate = dateToJsDate;
  readonly JsDateToDate = jsDateToDate;

  readonly form = this.fb.nonNullable.group({
    start: [undefined as Date | undefined],
    end: [undefined as Date | undefined],
  });

  readonly start = qpCast1('start');
  readonly end = qpCast1('end');
  readonly publisherId = qpCastN('publisherId');

  constructor() {
    const { start, end } = { start: this.start(), end: this.end() };
    if (start) {
      this.form.controls.start.patchValue(dateToJsDate(start));
    }
    if (end) {
      this.form.controls.end.patchValue(dateToJsDate(end));
    }
  }

  qpFilters = computed(() => ({
    start: this.start() || undefined,
    end: this.end() || undefined,
    publisherId: this.publisherId(),
  }));

  readonly filters$ = toObservable(this.qpFilters);

  readonly enrichedTopics$ = combineLatest([this.wkQp.obs$, this.filters$]).pipe(
    switchMap(([qp, filters]) => this.topicsService.listTopics({ ...qp, ...filters })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly publishers$: Observable<(Publisher & { isSelected: boolean })[]> = this.publishersService
    .listPublishers({ limit: 30 })
    .pipe(map(({ items }) => items.map((i) => ({ ...i, isSelected: !!this.publisherId().find((p) => p === i.id ) }))));

  setQpPublishers(publishers: structuredItem[]) {
    this.publisherId.set(publishers.filter((p) => p.isSelected).map(({ id }) => id));
    this.updatePagination({ first: 0 });
  }

  updatePagination(event: PaginatorState) {
    if (event.first !== undefined) {
      this.wkQp.offset.set(event.first);
    }
    if (event.rows !== undefined) {
      this.wkQp.limit.set(event.rows);
    }
  }

  updateStart(event: Date | undefined) {
    const date = event ? new Date(jsDateToDate(event)).toISOString() : undefined;
    this.form.controls.start.patchValue(event);
    this.start.set(date);
  }

  updateEnd(event: Date | undefined) {
    const date = event ? new Date(jsDateToDate(event)).toISOString() : undefined;
    this.form.controls.end.patchValue(event);
    this.end.set(date);
  }
}
