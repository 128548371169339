import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, ViewChild, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfirmationService as PrimeNgConfirmationService,
  MessageService,
  MenuItem,
  PrimeNGConfig,
} from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { Menu, MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { QueryParamsService } from '../services/queryparams.service';
import { skip } from 'rxjs';
import { GraphLayoutService } from '../services/graph-layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrl: './root.component.scss',
  standalone: true,
  imports: [AsyncPipe, ConfirmDialogModule, MenuModule, RouterOutlet, ToastModule],
  providers: [DialogService, MessageService, PrimeNgConfirmationService, TranslateService],
})
export class RootComponent {
  @ViewChild(Menu, { static: true }) menu!: Menu;

  readonly graphLayout = inject(GraphLayoutService);

  readonly translate = inject(TranslateService);
  readonly primeNgConfig = inject(PrimeNGConfig);
  readonly route = inject(ActivatedRoute);
  readonly router = inject(Router);
  readonly destroyRef = inject(DestroyRef);
  readonly qpSignals = inject(QueryParamsService);

  showChat: boolean;

  constructor() {
    this.showChat = !!location.href.includes('explore');
    const qpSub = toObservable(this.qpSignals.queryParams)
      .pipe(skip(1))
      .subscribe((queryParams) => {
        void this.router.navigate([], {
          queryParams,
          replaceUrl: true,
          queryParamsHandling: 'merge',
        });
      });

    const navigationSub = this.router.events.subscribe(() => (this.showChat = !!location.href.includes('explore')));

    this.destroyRef.onDestroy(() => {
      qpSub.unsubscribe();
      navigationSub.unsubscribe();
    });
  }

  toggleMenu(menuItems: MenuItem[], event: Event) {
    this.menu.model = menuItems;
    this.menu.toggle(event);
  }
}
