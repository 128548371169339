import { Component } from '@angular/core';

export interface simpleItem {
  id: string;
  label: string;
}

@Component({
  selector: 'app-simple-panel',
  templateUrl: './simple-panel.component.html',
  styleUrl: './simple-panel.component.scss',
  standalone: true,
  imports: [],
})
export class SimplePanelComponent {}
