/* eslint-disable no-param-reassign */
import { AsyncPipe, DOCUMENT, NgClass, NgStyle } from '@angular/common';
import { Component, computed, effect, ElementRef, inject, input, OnInit, signal, viewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, map, of, startWith, Subscription, switchMap } from 'rxjs';
import { Event, EventType, Room, SenderType } from 'src/app/api/conversation-backend-connector/models';

import { SvgIconComponent } from '@ngneat/svg-icon';
import { fadeInOut } from '../../animations/fadeInOut';
import { AssistantResponsePhase, AssistantResponsePhaseMap, User } from '../../models/chat';
import { TimeAgoPipe } from '../../pipes/time-ago.pipe';
import { ChatService } from '../../services/chat.service';
import { MessageContentComponent } from '../message-content/message-content.component';
import { handleTextareaChange } from '../../utils/text-manipulation';
import { TooltipModule } from 'primeng/tooltip';

const areAllContext = (events: Event[]) => events.length !== 0 && events.every((e) => e.type === EventType.SetContext);

const hasWidgetToFocus = (events: Event[]) =>
  events.find((e) => (e.type === EventType.Message ? e.content.includes('[widget:') : undefined));

const resetArea = (input: HTMLTextAreaElement) => {
  input.value = '';
  input.focus();
  input.style.setProperty('height', '24px');
};

const messagesOnly = (event: Event) => event.type === EventType.Message;

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MessageContentComponent,
    NgClass,
    NgStyle,
    SvgIconComponent,
    TimeAgoPipe,
    TooltipModule,
    TranslateModule,
  ],
  animations: [fadeInOut(200, 200)],
})
export class ConversationComponent implements OnInit {
  private readonly messagesChat = viewChild.required('messagesChat', { read: ElementRef });

  readonly askAIMessage = input<string | undefined>();
  readonly removeAIMessage = signal<boolean>(false);
  readonly _askAIMessage = computed<string | undefined>(() =>
    !this.removeAIMessage() ? this.askAIMessage() : undefined,
  );

  private readonly chatService = inject(ChatService);
  private readonly document = inject(DOCUMENT);

  private readonly subscriptions: Array<Subscription> = [];

  readonly AssistantResponsePhase = AssistantResponsePhase;
  readonly AssistantResponsePhaseMap = AssistantResponsePhaseMap;

  readonly EventType = EventType;
  readonly SenderType = SenderType;

  readonly areAllContext = areAllContext;
  readonly hasWidgetToFocus = hasWidgetToFocus;

  // Current user that is playing the demo
  readonly currentUser$ = new BehaviorSubject<User>({
    username: 'Anonymous Demo User',
    id: 'ANONYMOUS_ID',
  });
  get currentUser() {
    return this.currentUser$.value;
  }
  set currentUser(value: User) {
    this.currentUser$.next(value);
  }

  // Current room where the conversation is happening
  readonly room$ = this.chatService.currentRoom$;
  set room(value: Room | undefined) {
    this.chatService.currentRoom$.next(value);
  }
  get room() {
    return this.chatService.currentRoom$.value;
  }

  // Indicates if we're waiting a response from the Smart Assistant or not
  readonly isProcessing$ = new BehaviorSubject<boolean>(false);
  get isProcessing() {
    return this.isProcessing$.value;
  }
  set isProcessing(value: boolean) {
    this.isProcessing$.next(value);
  }

  // Retrieve flavour messages
  readonly processingMessage$ = this.chatService.phaseMessage$.pipe(map(([phase]) => phase));

  // Messages list
  readonly messages$ = this.chatService.currentRoom$.pipe(
    switchMap((room) => (room != null ? this.chatService.observeRoom(room).pipe(startWith([])) : of(undefined))),
  );

  readonly messagesOnly = messagesOnly;

  constructor() {
    effect(
      () => {
        if (this.askAIMessage() !== '') {
          this.removeAIMessage.set(false);
        }
      },
      { allowSignalWrites: true },
    );
  }

  async ngOnInit(): Promise<void> {
    await this.chatService.establishConnection();

    const room = await this.chatService.createRoom();
    this.chatService.currentRoom$.next(room);

    const chatSub = this.chatService.messages$
      // Perform actions on the UI when receiving a message
      .subscribe(async ([message]) => {
        // If it isn't an `EventType.Message`, we don't do anything
        if (message.type !== EventType.Message) {
          return;
        }

        // If the last message is from the User and there is no Human Operator connected,
        // we want to display the processing text in the UI
        this.isProcessing = message.senderType === SenderType.User;

        this.scrollToBottom();
      });

    this.subscriptions.push(chatSub);
  }

  async upsertMessage(input: HTMLTextAreaElement) {
    await this.chatService.postMessage(
      `${this._askAIMessage() ? this._askAIMessage() : ''} ${input.value}`,
      this.room?.id ?? '',
      undefined,
      undefined,
    );

    resetArea(input);
    this.removeAskAIMessage();
  }
  handleTextareaChange(textarea: HTMLTextAreaElement, ev?: KeyboardEvent) {
    if (handleTextareaChange(textarea, ev)) {
      return this.upsertMessage(textarea);
    }

    return undefined;
  }

  removeAskAIMessage() {
    this.removeAIMessage.set(true);
  }

  private scrollToBottom() {
    setTimeout(() => this.messagesChat().nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' }), 1000);
  }

  focusOnLatestWidget() {
    const widgets = this.document.body.getElementsByTagName('app-action-widget');
    requestAnimationFrame(() =>
      widgets.item(widgets.length - 1)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
    );
  }
}
