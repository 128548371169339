<div
  class="flex h-9 w-full items-center justify-start gap-2 overflow-hidden rounded-lg p-2 text-sm text-rest hover:bg-teal-100">
  <p-checkbox
    [binary]="true"
    [ngModel]="item().isSelected"
    (ngModelChange)="updateSelection($event)" />
  <div class="truncate mr-auto">{{ item().name }}</div>
  @if (item().color) {
    <p-colorPicker
      appendTo="body"
      styleClass="size-5"
      [ngModel]="item().color"
      (ngModelChange)="updateColor($event)" />
  }
</div>
