import { Component, input, model } from '@angular/core';
import { PanelItemComponent } from '../panel-item/panel-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { simpleItem } from '../simple-panel/simple-panel.component';

@Component({
  selector: 'app-single-choice-panel',
  templateUrl: './single-choice-panel.component.html',
  styleUrl: './single-choice-panel.component.scss',
  standalone: true,
  imports: [PanelItemComponent, TranslateModule],
})
export class SingleChoicePanelComponent {
  title = input<string>();
  items = input<(simpleItem | null)[]>();
  selectedItemId = model<string>('');
}
