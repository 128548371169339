/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminApi } from './services/admin-api';
import { ArticlesApi } from './services/articles-api';
import { AuthenticationApi } from './services/authentication-api';
import { AuthorsApi } from './services/authors-api';
import { DiagnosticsApi } from './services/diagnostics-api';
import { EmbeddingsApi } from './services/embeddings-api';
import { ExploreGraphApi } from './services/explore-graph-api';
import { FeedbacksApi } from './services/feedbacks-api';
import { PendingArticlesApi } from './services/pending-articles-api';
import { PublishersApi } from './services/publishers-api';
import { SegmentsApi } from './services/segments-api';
import { TopicsApi } from './services/topics-api';
import { UsersApi } from './services/users-api';
import { WebContentsApi } from './services/web-contents-api';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminApi,
    ArticlesApi,
    AuthenticationApi,
    AuthorsApi,
    DiagnosticsApi,
    EmbeddingsApi,
    ExploreGraphApi,
    FeedbacksApi,
    PendingArticlesApi,
    PublishersApi,
    SegmentsApi,
    TopicsApi,
    UsersApi,
    WebContentsApi,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
