@if (node(); as node) {
  <div
    class="flex flex-col gap-3 h-screen max-h-full overflow-hidden border-r border-r-rest-border py-12 px-3 bg-white">
    <div class="shrink-0 text-accent-dark font-semibold text-lg flex gap-2 items-center">
      <span>{{ (node.isTopic ? 'i18n.Common.topic' : 'i18n.Common.article') | translate }}</span>
      <button
        class="flex cursor-pointer items-center rounded-full border border-rest-border text-accent-dark p-2 bg-surface transition-all shrink-0 ml-auto"
        (click)="togglePanel.emit(false)">
        <svg-icon
          key="close-fill"
          class="text-black"
          size="md"></svg-icon>
      </button>
    </div>
    <div class="flex h-full flex-col grow gap-2">
      <a
        class="flex flex-col p-2 rounded-lg bg-surface gap-2 cursor-pointer shrink-0"
        [routerLink]="[(node.isTopic ? '../news/' : '../news/articles/') + node.id]">
        <div [ngClass]="{ header: !node.isTopic }">
          {{ node.title }}
        </div>
        @if (!node.isTopic) {
          <div class="flex items-center gap-2 text-rest text-xs">
            @if (node.publisher && publisherNames.includes(node.publisher.name)) {
              <img
                alt="publisher_thumbnail"
                class="rounded-full size-4 border border-rest-border"
                [src]="thumbnails[castToKey(node.publisher.name)]" />
              <div class="underline text-accent-dark capitalize">
                {{ node.publisher.name + ($any(node).date ? ',' : '') }}
              </div>
            }
            @if (node.date; as date) {
              <div class="text-accent-dark text-sm">
                {{ (date | ago).label | translate: { value: (date | ago).value } }}
              </div>
            }
          </div>
        }
      </a>

      <div class="flex grow flex-col overflow-hidden">
        <ng-content select="[related]"></ng-content>
      </div>
    </div>
  </div>
}
