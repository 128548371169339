import { Component, Input } from '@angular/core';
import { LatLngExpression, PolylineOptions, polyline } from 'leaflet';

import { MapLayer } from './map-layer';

@Component({
  selector: 'app-map-path',
  template: '',
  standalone: true,
  providers: [
    {
      provide: MapLayer,
      useExisting: MapPathComponent,
    },
  ],
})
export class MapPathComponent extends MapLayer {
  readonly leafletLayer = polyline([]);

  @Input() set path(path: LatLngExpression[] | LatLngExpression[][] | undefined) {
    this.leafletLayer.setLatLngs(path ?? []);
  }

  @Input() set options(options: PolylineOptions) {
    this.leafletLayer.setStyle(options);
  }
}
