import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { fadeInOut } from '../../../animations/fadeInOut';
import { NewsWidget } from '../../../models/widgets';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgClass, TranslateModule],
  animations: [fadeInOut(200, 200)],
})
export class NewsWidgetComponent {
  @Input() widget?: NewsWidget;
}
