import { Component, ElementRef, inject, Input } from '@angular/core';
import { icon, marker, PopupOptions } from 'leaflet';

import { MapLayer } from './map-layer';

@Component({
  selector: 'app-map-point',
  template: '<ng-content></ng-content>',
  standalone: true,
  providers: [
    {
      provide: MapLayer,
      useExisting: MapPointComponent,
    },
  ],
})
export class MapPointComponent extends MapLayer {
  private readonly element = inject(ElementRef);

  readonly leafletLayer = marker({ lat: 0, lng: 0 });

  @Input() set popup(options: PopupOptions | undefined | '') {
    if (options === undefined) {
      this.leafletLayer.unbindPopup();
    } else {
      this.leafletLayer.bindPopup(() => this.element.nativeElement, options === '' ? undefined : options);
    }
  }

  @Input() set lat(lat: number) {
    this.leafletLayer.setLatLng({ ...this.leafletLayer.getLatLng(), lat });
  }

  @Input() set lng(lng: number) {
    this.leafletLayer.setLatLng({ ...this.leafletLayer.getLatLng(), lng });
  }

  @Input() set title(title: string | undefined) {
    this.leafletLayer.options.title = title;

    if (title) {
      this.leafletLayer.bindTooltip(title, { direction: 'center', className: 'map-tooltip' });
    }
  }

  @Input() set icon(iconUrl: string | undefined) {
    if (!iconUrl) {
      return;
    }

    const markerIcon = icon({
      iconUrl,
      iconSize: [48, 48],
      className: 'rounded-full border border-rest-border shrink-0 aspect-square object-cover',
    });

    this.leafletLayer.setIcon(markerIcon);
  }
}
