import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TopicsApi } from '../api/backend-connector/services';
import { GetTopic$Params } from '../api/backend-connector/fn/topics/get-topic';
import { ListTopics$Params } from '../api/backend-connector/fn/topics/list-topics';

@Injectable({
  providedIn: 'root',
})
export class TopicsService {
  private readonly topicsApi = inject(TopicsApi);
  readonly refresh$ = new BehaviorSubject<void>(undefined);

  listTopics(params: ListTopics$Params) {
    return this.topicsApi.listTopics(params);
  }

  getTopic(params: GetTopic$Params) {
    return this.topicsApi.getTopic(params);
  }
}
