import { RouterLink } from '@angular/router';
import { Component, input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { castToKey } from 'src/app/pages/topics-list-page/topics-list-page.component';
import * as thumbnails from '../../../assets/thumbnails.json';
import { ArticleNode } from 'src/app/api/backend-connector/models';

@Component({
  selector: 'app-related-articles',
  templateUrl: './related-articles.component.html',
  styleUrl: './related-articles.component.scss',
  standalone: true,
  imports: [RouterLink, SvgIconComponent, TooltipModule, TranslateModule],
})
export class RelatedArticlesComponent {
  readonly relatedArticles = input<ArticleNode[] | null>(null);

  readonly castToKey = castToKey;
  readonly publisherNames = Object.keys(thumbnails);
  readonly thumbnails = thumbnails;
}
