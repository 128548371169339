import { Component, effect, ElementRef, inject, input, model, output, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Editor, NgxEditorModule } from 'ngx-editor';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { first, lastValueFrom, map } from 'rxjs';
import { RoomsApi } from 'src/app/api/conversation-backend-connector/services';
import { Action, AssistantResponseActionMap } from '../../models/chat';
import { ChatService } from 'src/app/services/chat.service';

import { Event as ChatEvent } from 'src/app/api/conversation-backend-connector/models';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgClass } from '@angular/common';
import schema from './schema';
import { SanitizeHtmlPipe } from '../../pipes/sanitizeHtml.pipe';
import { handleTextareaChange } from '../../utils/text-manipulation';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss',
  standalone: true,
  imports: [
    ButtonModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    FormsModule,
    NgClass,
    NgxEditorModule,
    ProgressSpinnerModule,
    SanitizeHtmlPipe,
    SvgIconComponent,
    TranslateModule,
  ],
  providers: [ConfirmationService],
})
export class TextEditorComponent {
  private readonly translate = inject(TranslateService);
  private readonly roomsApi = inject(RoomsApi);
  private readonly chatService = inject(ChatService);
  readonly globalAction = input<Action>();
  readonly askAIMessage = output<string>();
  private readonly confirmationService = inject(ConfirmationService);

  readonly editorRef = viewChild('ngxEditor', { read: ElementRef });

  readonly editor = new Editor({
    plugins: [],
    nodeViews: {},
    history: true,
    keyboardShortcuts: true,
    inputRules: true,
    schema,
  });

  readonly content = model<string | undefined>(undefined);
  readonly AssistantResponseActionMap = AssistantResponseActionMap;

  readonly handleTextareaChange = handleTextareaChange;

  constructor() {
    effect(() => {
      const global = this.globalAction();
      if (global) {
        this.handleGlobalAction(global);
      }
    });
  }

  askForDismiss(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translate.instant('i18n.Common.discardAISuggestions'),
      header: 'Confirmation',
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => this.dismissDialog(),
      reject: () => undefined,
    });
  }

  readonly contextMenuItems: MenuItem[] = [
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.checkSources'),
      disabled: true,
      icon: 'check-sources',
    },
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.suggestSources'),
      disabled: true,
      icon: 'suggest-sources',
    },
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.enhanceWriting'),
      command: () => this.handleSelection('write_better'),
      icon: 'write-better',
    },
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.synthesize'),
      command: () => this.handleSelection('synthesize'),
      icon: 'synthetize',
    },
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.grammarCheck'),
      command: () => this.handleSelection('grammar_check'),
      icon: 'grammar-check',
    },
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.simplifyLanguage'),
      command: () => this.handleSelection('simplify'),
      icon: 'simplify',
    },
    {
      label: this.translate.instant('i18n.TextEditor.ContextMenu.askAI'),
      command: () => {
        this.selection = this.editor.view.state.selection;
        this.selectionText = this.editor.view.state.doc.textBetween(this.selection.from, this.selection.to, ' ');
        this.askAIMessage.emit(this.selectionText);
        this.selection = undefined;
        this.selectionText = undefined;
      },
      icon: 'ask-coeditor',
    },
  ];

  aiAction?: Action;
  selection?: { from: number; to: number };
  selectionText?: string;
  aiResponse?: string;
  dialogTop = 0;
  dialogLeft = 0;

  handleSelection(action: Action) {
    this.aiAction = action;
    this.selection = this.editor.view.state.selection;
    this.selectionText = this.editor.view.state.doc.textBetween(this.selection.from, this.selection.to, ' ');
    void this.callAgent(action);
  }

  async handleGlobalAction(action: Action) {
    this.aiAction = action;
    this.selection = { from: 0, to: 0 };
    this.selectionText = this.editor.view.dom.innerHTML;
    await this.callAgent(action);
  }

  applySelection(method: 'replace' | 'addToBottom'): void {
    if (!this.aiResponse) {
      return;
    }

    if (this.globalAction()) {
      const previousText = this.editor.view.dom.innerHTML;
      this.editor.view.dom.innerHTML = method === 'replace' ? this.aiResponse : previousText + this.aiResponse;
      this.dismissDialog();
      return;
    }
    const currentSelection = this.editor.view.state.selection;
    const { from, to } = currentSelection;

    const transaction =
      method === 'replace'
        ? this.editor.view.state.tr.replaceWith(from, to, this.editor.view.state.schema.text(this.aiResponse))
        : this.editor.view.state.tr.replaceWith(to, to + 1, this.editor.view.state.schema.text(' ' + this.aiResponse));
    this.editor.view.dispatch(transaction);
    this.dismissDialog();
  }

  dismissDialog() {
    this.selection = undefined;
    this.selectionText = undefined;
    this.aiAction = undefined;
    this.aiResponse = undefined;
  }

  async callAgent(desired_action: Action) {
    const message = {
      desired_action,
      raw: this.selectionText,
    };

    const room = await lastValueFrom(this.roomsApi.createRoom({ body: {} }));

    this.chatService
      .observeRoom(room)
      .pipe(
        map((e): ChatEvent | undefined => e[e.length - 1]),
        first((e): e is ChatEvent => e?.senderType === 'agent'),
      )
      .subscribe((e) => (this.aiResponse = e.content));

    await this.chatService.postMessage(JSON.stringify(message), room.id);
  }

  setDialogPosition(event: MouseEvent) {
    this.dialogLeft = event.pageX;
    this.dialogTop = event.pageY;
  }
}
