import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cowrite-header',
  template: `
    <div class="flex flex-row items-center w-full text-base leading-6 font-medium text-rest">
      <!-- <div
        class="flex flex-row items-center gap-2 hover:opacity-60 cursor-pointer transition-all"
        routerLink="">
        <svg-icon key="arrow-back-line"></svg-icon>
        <span>{{ 'i18n.CowriteHeader.myArticles' | translate }}</span>
      </div> -->
      <div class="flex flex-row items-center gap-4 ml-auto">
        <span>{{ 'i18n.Common.lastSave' | translate: { date: today | date: 'dd MMMM, HH:mm' } }}</span>
        <button
          class="flex flex-row items-center rounded-full border border-rest-border bg-surface shrink-0 p-2 opacity-30 pointer-events-none touch-none">
          <svg-icon
            size="md"
            key="more-fill"></svg-icon>
        </button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [DatePipe, RouterLink, SvgIconComponent, TranslateModule],
})
export default class CowriteHeaderComponent {
  readonly today = new Date();
}
