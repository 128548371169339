import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
  standalone: true,
  imports: [NgClass],
})
export class TooltipComponent {
  readonly title = input.required<string>();
  readonly body = input<string>();
}
