import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { castToKey } from 'src/app/pages/topics-list-page/topics-list-page.component';
import * as thumbnails from '../../../assets/thumbnails.json';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { CustomNode } from 'src/app/utils/graph';

@Component({
  selector: 'app-node-details-panel',
  standalone: true,
  imports: [NgClass, RouterLink, SvgIconComponent, TimeAgoPipe, TranslateModule],
  templateUrl: './node-details-panel.component.html',
  styleUrl: './node-details-panel.component.scss',
})
export class NodeDetailsPanelComponent {
  readonly node = input.required<CustomNode>();
  readonly togglePanel = output<boolean>();

  readonly castToKey = castToKey;
  readonly publisherNames = Object.keys(thumbnails);
  readonly thumbnails = thumbnails;
}
