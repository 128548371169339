/* eslint-disable no-param-reassign */
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SanitizeHtmlPipe } from '../../pipes/sanitizeHtml.pipe';
import { ReferenceWidgetComponent } from './reference-widget/reference-widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { TypeWritePipe } from '../../pipes/typewrite.pipe';
import { SenderType } from '../../api/conversation-backend-connector/models';
import { NewsWidget, ReferenceWidget, Widget } from '../../models/widgets';
import { cast } from '../../utils/cast';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NewsWidgetComponent } from './news-widget/news-widget.component';

const scrollWidgets = (widgetContainer: HTMLDivElement, to: 'prev' | 'next') => {
  switch (to) {
    case 'prev':
      requestAnimationFrame(() =>
        widgetContainer.scrollTo({
          left: widgetContainer.scrollLeft - 250,
          behavior: 'smooth',
        }),
      );
      break;

    case 'next':
      requestAnimationFrame(() =>
        widgetContainer.scrollTo({
          left: widgetContainer.scrollLeft + 250,
          behavior: 'smooth',
        }),
      );
      break;
    default:
      break;
  }
};

@Component({
  selector: 'app-message-content',
  templateUrl: './message-content.component.html',
  styleUrls: ['./message-content.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NewsWidgetComponent,
    NgClass,
    NgStyle,
    ReferenceWidgetComponent,
    SanitizeHtmlPipe,
    SvgIconComponent,
    TranslateModule,
    TypeWritePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageContentComponent implements OnInit {
  @ViewChild('messageContent') messageContent!: ElementRef<HTMLFormElement>;
  @Input() content = '';
  @Input() senderType?: SenderType;

  readonly SenderType = SenderType;

  readonly castReferenceWidget = cast<ReferenceWidget>;
  readonly castNewsWidget = cast<NewsWidget>;
  readonly scrollWidgets = scrollWidgets;

  widgets?: Widget[];
  contentAfterWidgets?: string;
  expandedWidget?: Widget;

  ngOnInit(): void {
    this.widgets = this.content
      .match(/\[[^[\]]*?(?:(?:(?!\[).)*?\[[^[\]]*?\].*?)*?\]/g)
      ?.filter((widget): widget is string => widget != null)
      .flatMap((match) => JSON.parse(match) as Widget);

    if (!this.widgets) {
      return;
    }

    // Remove widgets from text message
    this.content = this.content.replace(/\[[^\]]*\]/g, 'FE_WIDGET_CONTENT');

    /* TODO: Let's make this better, for now it is required for table widget */
    if (this.content.endsWith('}]')) {
      this.content = this.content.slice(0, -2);
    }

    const contentFragments = this.content.split('FE_WIDGET_CONTENT');

    requestAnimationFrame(() => this.messageContent.nativeElement.scrollIntoView());

    this.content = contentFragments.at(0) ?? '';
    if (contentFragments.filter((fragment) => fragment !== '').length === 1) {
      return;
    }

    this.contentAfterWidgets = contentFragments.filter((fragment) => fragment !== '').at(-1);
  }

  handleWidgetExpansion(widgetContainer: HTMLDivElement, widget: Widget) {
    requestAnimationFrame(() => widgetContainer.scrollTo({ left: 0 }));

    if (this.expandedWidget === widget) {
      this.expandedWidget = undefined;
      widgetContainer.style.height = 'unset';
      return;
    }

    this.expandedWidget = widget;
    widgetContainer.style.height = '220px';
  }
}
