import { ColorPickerModule } from 'primeng/colorpicker';
import { Component, model } from '@angular/core';
import { structuredItem } from '../structured-panel/structured-panel.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-structured-item',
  templateUrl: './structured-item.component.html',
  styleUrl: './structured-item.component.scss',
  standalone: true,
  imports: [CheckboxModule, ColorPickerModule, FormsModule],
})
export class StructuredItemComponent {
  readonly item = model.required<structuredItem>();

  updateColor(color: string) {
    this.item.update((v) => ({ ...v, color }));
  }

  updateSelection(isSelected: boolean) {
    this.item.update((v) => ({ ...v, isSelected }));
  }
}
