import { Injectable, inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Publisher, PublisherResponsePage } from '../api/backend-connector/models';
import { PublishersApi } from '../api/backend-connector/services';

@Injectable({
  providedIn: 'root',
})
export class PublishersService {
  private readonly publishersApi = inject(PublishersApi);
  readonly refresh$ = new BehaviorSubject<void>(undefined);

  listPublishers(params: Parameters<typeof this.publishersApi.listPublishers>[0]): Observable<PublisherResponsePage> {
    return this.publishersApi.listPublishers(params);
  }

  getPublisher(params: Parameters<typeof this.publishersApi.getPublisher>[0]): Observable<Publisher> {
    return this.publishersApi.getPublisher(params);
  }
}
