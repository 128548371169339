<div class="flex items-center gap-1 text-accent-dark font-bold text-lg pb-3 border-b border-rest-border">
  {{ 'i18n.RelatedArticles.title' | translate }}
  <svg-icon
    class="text-rest"
    key="information-line"
    tooltipPosition="left"
    [pTooltip]="articlesTooltip"></svg-icon>
  <ng-template #articlesTooltip>
    <div class="!p-0 text-accent-dark bg-surface text-sm">
      {{ 'i18n.ExplorePage.Tooltip.relatedArticles' | translate }}
    </div>
  </ng-template>
</div>
@if (relatedArticles(); as articles) {
  <div class="flex h-fit flex-col grow overflow-auto">
    @for (article of articles; track $index) {
      <a
        class="flex flex-col pl-3 pr-5 cursor-pointer border-b border-rest-border pb-2 pt-1"
        [routerLink]="['../news/articles/' + article.id]">
        <div class="font-normal line-clamp-2 font-article">
          {{ article.title }}
        </div>
        <div class="flex flex-row items-center flex-wrap gap-1.5 text-xs text-accent-dark font-medium">
          @if (article?.authors && (article?.authors ?? []).length > 0) {
            <div class="flex items-center line-clamp-2 flex-wrap gap-1.5 text-xs text-accent-dark">
              <span class="text-rest">{{ 'i18n.Common.of' | translate }}</span>
              @for (author of article?.authors; track $index) {
                <u class="min-w-max no-underline text-accent-dark">
                  {{ author.name + ($index === article.authors.length - 1 ? '' : ',') }}
                </u>
              }
            </div>
          }

          @if (article.publisher; as publisher) {
            <span class="text-xs text-rest">{{ 'i18n.Common.for' | translate }}</span>
            <div class="flex items-center gap-0.5 min-w-fit">
              @if (publisherNames.includes(publisher.name)) {
                <img
                  alt="publisher_thumbnail"
                  class="rounded-full size-4 border border-rest-border"
                  [src]="thumbnails[castToKey(publisher.name)]" />
              }
              <div class="text-accent-dark capitalize">
                {{ publisher.name }}
              </div>
            </div>
          }
        </div>
      </a>
    }
  </div>
}
