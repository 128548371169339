<div class="flex items-center shrink-0 gap-1 text-accent-dark font-bold text-lg pb-2">
  {{ 'i18n.RelatedTopics.title' | translate }}
  <svg-icon
    class="text-rest"
    key="information-line"
    tooltipPosition="left"
    [pTooltip]="relatedTopic"></svg-icon>
  <ng-template #relatedTopic>
    <div class="!p-0 text-accent-dark bg-surface text-sm">
      {{ 'i18n.ExplorePage.Tooltip.relatedTopic' | translate }}
    </div>
  </ng-template>
</div>
<div class="flex h-fit flex-col grow gap-3 overflow-auto">
  @if (relatedTopicsMap(); as relatedTopicsMap) {
    @for (topicMap of relatedTopicsMap; track $index) {
      <div class="flex flex-col gap-2 h-fit">
        <div
          class="flex size-full flex-col gap-2 pb-1 px-3"
          [ngClass]="{ 'border-b border-rest-border': $index !== relatedTopicsMap.length - 1 }">
          <a
            class="text-xl font-semibold truncate cursor-pointer"
            [routerLink]="['../news/' + topicMap.topic]">
            {{ topicMap.topic.title }}
          </a>
          @for (article of topicMap.articles; track $index) {
            <div
              class="w-full flex items-center gap-2 cursor-pointer"
              [ngClass]="{ 'border-b border-rest-border': $index !== topicMap.articles.length - 1 }">
              @if (article.publisher; as publisher) {
                <img
                  alt="publisher_thumbnail"
                  class="rounded-full size-4 border border-rest-border"
                  [src]="thumbnails[castToKey(publisher.name)]" />
              }
              <a
                class="text-sm w-full grow truncate font-article"
                [routerLink]="['../news/articles/' + article.id]">
                {{ article.title }}
              </a>
            </div>
          }
        </div>
      </div>
    }
  }
</div>
