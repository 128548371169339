import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { routes } from './app.routes';
import { unknownIcon } from './svg/unknown';
import { DynamicImportLoader } from './i18n/dynamic-import-loader';

import { environment } from 'src/environment/environment';
import { aiIcon } from './svg/ai';
import { ApiModule as BackendApiModule } from './api/backend-connector/api.module';
import { ApiModule as ConversationAgentApiModule } from './api/conversation-agent-connector/api.module';
import { ApiModule as ConversationBackendApiModule } from './api/conversation-backend-connector/api.module';
import { ApiModule as ConversationBackendMgmtApiModule } from './api/conversation-backend-mgmt-connector/api.module';
import { userIcon } from './svg/user';
import { arrowUpIcon } from './svg/arrow-up';
import { arrowRightIcon } from './svg/arrow-right';
import { quillPenLineIcon } from './svg/quill-pen-line';
import { compassLineIcon } from './svg/compass-line';
import { newsLineIcon } from './svg/news-line';
import { arrowBackLineIcon } from './svg/arrow-back-line';
import { moreFillIcon } from './svg/more-fill';
import { informationLineIcon } from './svg/information-line';
import { logoutIcon } from './svg/logout';
import { linkLineIcon } from './svg/link-line';
import { searchIcon } from './svg/search';
import { closeFillIcon } from './svg/close-fill';
import { checkSourcesIcon } from './svg/check-sources';
import { writeBetterIcon } from './svg/write-better';
import { suggestSourcesIcon } from './svg/suggest-sources';

import { grammarCheckIcon } from './svg/grammar-check';
import { askCoeditorIcon } from './svg/ask-coeditor';
import { simplifyIcon } from './svg/simplify';
import { bodoniFrostIcon } from './svg/bodoni-frost';
import { synthetizeIcon } from './svg/synthetize';
import { checkLineIcon } from './svg/check-line';

const svgIcons = [
  aiIcon,
  arrowBackLineIcon,
  arrowRightIcon,
  arrowUpIcon,
  askCoeditorIcon,
  bodoniFrostIcon,
  checkLineIcon,
  checkSourcesIcon,
  closeFillIcon,
  compassLineIcon,
  grammarCheckIcon,
  informationLineIcon,
  linkLineIcon,
  logoutIcon,
  moreFillIcon,
  newsLineIcon,
  quillPenLineIcon,
  searchIcon,
  simplifyIcon,
  suggestSourcesIcon,
  synthetizeIcon,
  unknownIcon,
  userIcon,
  writeBetterIcon,
];
export default svgIcons;

export type SvgName = (typeof svgIcons)[number]['name'];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideSvgIconsConfig({
      icons: svgIcons,
      missingIconFallback: unknownIcon,
    }),
    importProvidersFrom(
      BackendApiModule.forRoot({
        rootUrl: '/api',
      }),
      ConversationAgentApiModule.forRoot({
        rootUrl: '/api-conversation-agent',
      }),
      ConversationBackendApiModule.forRoot({
        rootUrl: '/api-conversation-backend',
      }),
      ConversationBackendMgmtApiModule.forRoot({
        rootUrl: '/api-conversation-backend',
      }),
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLang,
        loader: {
          provide: TranslateLoader,
          useClass: DynamicImportLoader,
        },
      }),
      BrowserAnimationsModule,
      HttpClientModule,
    ),
  ],
};
