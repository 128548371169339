<app-simple-panel class="block max-h-[inherit]">
  <ng-container header>
    @if (title(); as title) {
      <h1 class="size-0 truncate">{{ title }}</h1>
    }
    <p-iconField
      class="bordless-search"
      iconPosition="left">
      <svg-icon
        key="search"
        class="text-rest ml-3"></svg-icon>
      <input
        pAutoFocus
        class="px-4 py-1.5 text-sm text-primary outline-none"
        [placeholder]="'i18n.StructuredPanel.search' | translate"
        [(ngModel)]="query" />
    </p-iconField>
  </ng-container>

  @for (item of filteredItems(); track $index) {
    <app-structured-item
      [item]="item"
      (itemChange)="updateItem($event)"></app-structured-item>
  } @empty {
    <div class="py-1 pl-2.5 text-sm italic text-rest">{{ 'i18n.Common.noItems' | translate }}</div>
  }
</app-simple-panel>
