import { ArticleNodeAuthor, Publisher, RelatedTo } from '../api/backend-connector/models';

export type CustomNode =
  | {
      date?: string | null;
      id: string;
      authors: Array<ArticleNodeAuthor>;
      publisher?: Publisher | null;
      relatedTo: Array<RelatedTo>;
      title?: string | null;
      label: string | null | undefined;
      color?: string | null;
      isTopic: false;
    }
  | {
      articlesCount: number;
      id: string;
      relatedTo: Array<RelatedTo>;
      title?: string | null;
      label: string | null | undefined;
      color?: string | null;
      isTopic: true;
    };

export const colors = [
  '#22D68D',
  '#1FD096',
  '#1DCA9F',
  '#1AC4A9',
  '#18BEB2',
  '#15B7BB',
  '#13B1C4',
  '#10ABCE',
  '#0EA5D7',
  '#0B9FE0',
];

export const TOPIC = '#64748b';
export const ARTICLE = '#94a3b8';
export const LINKLESS_ARTICLE = '#cbd5e1';
export const RELATED_NODES = '#92cccf';
export const EDGE = '#92cccf';
