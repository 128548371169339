@for (item of items(); track $index) {
  @if (item) {
    <app-panel-item
      [label]="'i18n.ExplorePage.Title.period' | translate"
      [id]="item.id"
      [isSelected]="!!selectedItemId()"
      (selectedIdChange)="selectedItemId.set(item.id)"></app-panel-item>
  } @else {
    <div class="flex h-10 items-center gap-2 px-2">
      <div class="mt-1 size-4 rounded bg-slate-200"></div>
      <div class="mt-1 h-4 grow rounded bg-slate-200"></div>
    </div>
  }
} @empty {
  <div class="py-1 pl-2.5 text-sm italic text-rest">{{ 'i18n.Common.noItems' | translate }}</div>
}
