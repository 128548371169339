@if (widget) {
  <a
    class="relative flex h-[140px] select-none py-2 transition-all hover:cursor-pointer hover:!text-blue-500 hover:opacity-90"
    target="_blank"
    [href]="domSanitizer.bypassSecurityTrustUrl(widget.link)">
    <div class="flex h-full w-full flex-col gap-1 rounded-md border border-zinc-400 p-2 text-sm">
      <div class="-mx-2 -mt-2 flex grow flex-col items-center justify-center overflow-hidden rounded-t-md">
        @if (widget.imageUrl) {
          <span class="capitalize-first truncate px-4 text-lg font-extrabold">{{ widget.widget_type }}</span>
          <span class="max-w-48 truncate px-4 text-sm">{{ widget.link }}</span>
        }

        <img
          alt="Website image preview"
          class="object-contain"
          [src]="widget.imageUrl" />
      </div>
      <span class="text-base font-bold -mx-3 px-3 pt-1 border-t border-t-zinc-400">{{ widget.title }}</span>
    </div>
  </a>
}
