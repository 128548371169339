<div class="flex h-full flex-col grow overflow-hidden relative">
  <div class="flex gap-3 shrink-0 z-10">
    <div
      class="flex justify-start items-center gap-1 grow font-semibold p-12 z-10 from-white to-transparent bg-gradient-to-b">
      {{ 'i18n.ExplorePage.title' | translate }}
      <button
        class="flex items-center gap-1 text-accent"
        (click)="periodOp.toggle($event)">
        {{ 'i18n.ExplorePage.Title.period' | translate }}
        <svg-icon
          class="text-rest -rotate-90"
          key="arrow-back-line"></svg-icon>
      </button>

      @if (publishers$ | async) {
        <button
          class="flex items-center gap-1 rounded-full bg-surface border border-rest-border hover:bg-rest-hover px-3 py-1.5 ml-auto"
          (click)="publisherOp.toggle($event)">
          @if (calcSelectedPublishers(publishers()); as selected) {
            {{
              (selected === 1
                ? 'i18n.ExplorePage.Title.selectedPublisher'
                : 'i18n.ExplorePage.Title.selectedPublishers'
              ) | translate: { value: selected }
            }}
          } @else {
            {{ 'i18n.ExplorePage.Title.publishers' | translate }}
          }
          <svg-icon
            class="text-rest -rotate-90"
            key="arrow-back-line"></svg-icon>
        </button>
      }
    </div>
    <p-overlayPanel
      #publisherOp
      appendTo="body"
      styleClass="filter-panel">
      <ng-template pTemplate="content">
        <app-structured-panel
          class="block max-h-56"
          [(items)]="publishers">
        </app-structured-panel>
      </ng-template>
    </p-overlayPanel>
    <p-overlayPanel
      #periodOp
      appendTo="body"
      styleClass="filter-panel">
      <ng-template pTemplate="content">
        @if (period()?.id; as periodId) {
          <app-single-choice-panel
            [selectedItemId]="periodId"
            [items]="(periods$ | async) ?? []"
            (selectedItemIdChange)="selectPeriod($event)">
          </app-single-choice-panel>
        }
      </ng-template>
    </p-overlayPanel>
  </div>

  @if (period(); as period) {
    <app-graph
      class="size-full absolute"
      [period]="period"
      [query]="query.value"
      [selectedPublisher]="publishers()"
      [(relatedArticles)]="relatedArticles"
      [(relatedTopics)]="relatedTopics"
      [(selectedNode)]="selectedNode"></app-graph>
  }
</div>
<div
  class="h-full z-10 flex flex-col gap-2.5 shrink-0 transition-all border-l border-rest-border pb-6"
  [ngClass]="{
    'w-0': !isPanelOpen,
    'w-[400px]': isPanelOpen,
  }">
  @if (selectedNode(); as node) {
    <app-node-details-panel
      [node]="node"
      (togglePanel)="togglePanel(false)">
      <ng-container related>
        @if (node.isTopic) {
          <app-related-articles
            class="flex flex-col h-full grow overflow-hidden pb-4"
            [relatedArticles]="relatedArticles()"></app-related-articles>
        } @else {
          <app-related-topics
            class="flex flex-col h-full grow overflow-hidden pb-4"
            [relatedTopicsMap]="relatedTopicsMap()"></app-related-topics>
        }
      </ng-container>
    </app-node-details-panel>
  }
</div>
