import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as Showdown from 'showdown';

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  private readonly _sanitizer = inject(DomSanitizer);
  private readonly converter = new Showdown.Converter();

  transform(v: string | null): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.converter.makeHtml(v ?? ''));
  }
}
