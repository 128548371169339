<div
  class="text-accent-dark text-xs"
  [ngClass]="{ 'font-article !text-sm': body() }">
  {{ title() }}
</div>
@if (body(); as innerHTML) {
  <div
    class="text-rest text-[10px]"
    [innerHTML]="innerHTML"></div>
}
