import { Injectable, computed } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { qpCast1, qpMap1 } from './queryparams.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

type StandardQP<Sort extends string[] | undefined = string[] | undefined> = {
  limit?: number;
  offset?: number;
  sort?: Sort;
  query?: string | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class WkQueryParamsService {
  readonly query = qpCast1('query');
  readonly limit = qpMap1('limit', parseInt);
  readonly offset = qpMap1('offset', parseInt);
  readonly sortBy = qpCast1('sortBy');

  readonly sortField = computed(() => this.sortBy()?.replace(/^-/, ''));
  readonly sortOrder = computed(() => (this.sortBy()?.startsWith('-') ? -1 : 1));

  readonly signal = computed(() => {
    const limit = this.limit();
    const offset = this.offset();
    const sort = this.sortBy();
    const query = this.query();

    return {
      query,
      limit,
      offset,
      sort: sort == null ? undefined : [sort],
    };
  });

  readonly obs$ = toObservable(this.signal).pipe(
    debounceTime(300),
    distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
  );

  getValue<T extends StandardQP>() {
    return this.signal() as StandardQP<T['sort']>;
  }
}
