<div class="flex h-full pt-12 w-[calc(100vw-224px)]">
  <div class="flex grow overflow-x-auto flex-col">
    <div class="flex w-full flex-row flex-wrap gap-3 items-center justify-start shrink-0 pt-0.5 px-6">
      <div class="grow font-semibold flex gap-2 items-center text-lg">
        {{ 'i18n.TopicsListPage.topics' | translate }}
        <svg-icon
          class="text-slate-500"
          key="information-line"
          [autoHide]="false"
          [pTooltip]="topicsTooltip"></svg-icon>
        <ng-template #topicsTooltip>
          <div class="flex !p-0 text-accent-dark bg-surface text-sm">
            {{ 'i18n.TopicsListPage.Tooltip.topics' | translate }}
          </div>
        </ng-template>
      </div>
      <p-iconField
        class="search"
        iconPosition="left">
        <svg-icon
          key="search"
          class="text-rest mr-2"></svg-icon>
        <input
          pInputText
          class="text-rest bg-surface"
          [placeholder]="'i18n.Common.search' | translate"
          [(ngModel)]="wkQp.query" />
      </p-iconField>
      <div class="flex gap-1 items-center">
        <p-calendar
          class="search searchFrom w-full"
          appendTo="body"
          dateFormat="dd/mm/y"
          [placeholder]="'i18n.Common.from' | translate"
          [showClear]="true"
          [ngModel]="form.controls.start"
          (ngModelChange)="updateStart($event)">
        </p-calendar>
        <p-calendar
          class="search searchTo w-full"
          appendTo="body"
          dateFormat="dd/mm/y"
          [placeholder]="'i18n.Common.to' | translate"
          [showClear]="true"
          [ngModel]="form.controls.start"
          (ngModelChange)="updateEnd($event)">
        </p-calendar>
      </div>
      <p-button
        class="filter outline-teal-100 outline outline-[0px]"
        [class.publisherSelection]="!!publisherId().length"
        (click)="publisherOp.toggle($event)">
        {{ 'i18n.TopicsListPage.Filters.publishers' | translate }}
        <svg-icon
          key="arrow-back-line"
          class="-rotate-90"></svg-icon>
      </p-button>
      <p-overlayPanel
        #publisherOp
        appendTo="body"
        styleClass="filter-panel">
        <ng-template pTemplate="content">
          <app-structured-panel
            class="max-h-72 overflow-auto"
            [items]="(publishers$ | async) ?? []"
            (itemsChange)="setQpPublishers($event)">
          </app-structured-panel>
        </ng-template>
      </p-overlayPanel>
    </div>
    @if (enrichedTopics$ | async; as enrichedTopics) {
      <div
        class="flex max-h-[calc(100vh-140px)] flex-col grow items-center gap-1 overflow-auto pb-0.5 pl-3 border-r border-r-slate-200">
        <div class="flex size-full min-h-fit flex-col gap-1.5 px-3 pb-3">
          @for (topic of enrichedTopics?.items; track $index) {
            <div class="flex flex-col w-full gap-2 border-b border-slate-200 p-3">
              <div class="w-full flex flex-col gap-1.5 shrink-0 justify-between text-sm">
                <a
                  class="shrink-0 cursor-pointer text-2xl font-semibold"
                  [routerLink]="[topic.id]">
                  {{ topic.title }}
                </a>
                <div class="line-clamp-2">{{ topic.shortSummary }}</div>
              </div>
              <div class="grid grid-cols-3 w-full gap-4 shrink-0">
                @for (article of topic.articles; track $index) {
                  <div class="flex flex-col gap-1 p-2">
                    <a
                      class="line-clamp-2 font-article cursor-pointer font-normal"
                      [routerLink]="['./articles/' + article.id]">
                      {{ '"' + article.title + '"' }}
                    </a>

                    <div class="flex gap-1">
                      @if (article.publisher && publisherNames.includes(article.publisher.name)) {
                        <img
                          alt="publisher_thumbnail"
                          class="rounded-full size-4 border border-rest-border mt-0.5"
                          [src]="thumbnails[castToKey(article.publisher.name)]" />
                      }
                      <div class="text-rest text-sm">
                        @if (article.date) {
                          {{ ((article.date | ago).label | translate) + ',' }}
                        }
                        <u>{{ article.publisher!.name }}</u>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
      <div class="shrink-0">
        <p-paginator
          [first]="wkQp.offset() ?? 0"
          [totalRecords]="enrichedTopics.count"
          [rows]="wkQp.limit() ?? 20"
          [rowsPerPageOptions]="[5, 10, 20, 50]"
          [showCurrentPageReport]="true"
          (onPageChange)="updatePagination($event)" />
      </div>
    } @else {
      @defer (on timer(0.1s)) {
        <div class="flex size-full overflow-hidden flex-col gap-2">
          @for (item of [].constructor(6); track $index) {
            <div class="flex flex-col size-full gap-1.5 p-3 border-b border-rest-border">
              <div
                class="flex h-16 w-full items-start gap-1.5"
                styleClass="!h-full">
                <p-skeleton
                  class="size-full"
                  styleClass="!h-full"></p-skeleton>
                <p-skeleton
                  class="w-16 h-7"
                  styleClass="!h-full"></p-skeleton>
              </div>
              <div class="flex h-16 w-full items-start gap-1.5">
                <p-skeleton
                  class="size-full"
                  styleClass="!h-full"></p-skeleton>
              </div>
              <div class="flex h-16 w-full items-start gap-1.5">
                <p-skeleton
                  class="size-full"
                  styleClass="!h-full"></p-skeleton>
                <p-skeleton
                  class="size-full"
                  styleClass="!h-full"></p-skeleton>
                <p-skeleton
                  class="size-full"
                  styleClass="!h-full"></p-skeleton>
                <p-skeleton
                  class="min-w-9"
                  styleClass="!h-full"></p-skeleton>
              </div>
            </div>
          }
        </div>
      }
    }
  </div>
  <div class="flex flex-col h-[calc(100vh-48px)] min-w-[450px] w-[450px] gap-3 px-6 grow pb-5 shrink-0 overflow-hidden">
    <div class="flex items-center gap-2 text-lg font-semibold shrink-0">
      {{ 'i18n.TopicsListPage.geoIntelligence' | translate }}
      <svg-icon
        class="text-slate-500"
        key="information-line"
        [autoHide]="false"
        [pTooltip]="geoIntelligenceTooltip"></svg-icon>
      <ng-template #geoIntelligenceTooltip>
        <div class="flex !p-0 text-accent-dark bg-surface text-sm">
          {{ 'i18n.TopicsListPage.Tooltip.geoIntelligence' | translate }}
        </div>
      </ng-template>
    </div>
    <div class="flex justify-center items-center bg-slate-100 grow rounded-md overflow-hidden">
      <app-map-visualizer
        class="h-full w-full"
        [center]="{ lat: 41.9, lng: 12.496 }">
        @for (topic of (enrichedTopics$ | async)?.items; track $index) {
          @for (article of topic?.articles; track $index) {
            @if (article?.data?.coordinates?.lat && article?.data?.coordinates?.lng) {
              <app-map-point
                [title]="article.title ?? ''"
                [icon]="article.data?.top_image"
                [lat]="article.data.coordinates.lat"
                [lng]="article.data.coordinates.lng"></app-map-point>
            }
          }
        }
      </app-map-visualizer>
    </div>
  </div>
</div>
