import { Topic } from './../../api/backend-connector/models/topic';
import { AsyncPipe, JsonPipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, filter, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { ArticlesService } from '../../services/articles.service';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TopicsService } from 'src/app/services/topics.service';
import { copyContent } from 'src/app/utils/copy-to-clipboard';
import { castToKey } from '../topics-list-page/topics-list-page.component';

import * as thumbnails from '../../../assets/thumbnails.json';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { Article } from 'src/app/api/backend-connector/models';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-article-details-page',
  templateUrl: './article-details-page.component.html',
  styleUrl: './article-details-page.component.scss',
  standalone: true,
  imports: [AsyncPipe, NgClass, RouterLink, SvgIconComponent, TimeAgoPipe, TooltipModule, TranslateModule, JsonPipe],
})
export class ArticleDetailsPageComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly articlesService = inject(ArticlesService);
  private readonly topicsService = inject(TopicsService);

  readonly castToKey = castToKey;
  readonly copyContent = copyContent;
  readonly publisherNames = Object.keys(thumbnails);
  readonly thumbnails = thumbnails;

  readonly relatedArticles$ = this.articlesService.listArticles({ limit: 5 }).pipe(map(({ items }) => items));
  readonly relatedTopics$: Observable<Topic[]>;
  readonly dependantArticles$ = new BehaviorSubject<{ topicId: string; articles: Observable<Article[]> }[]>([]);
  readonly relatedTopicIds$ = new BehaviorSubject<string[]>([]);
  readonly article$ = this.route.paramMap.pipe(
    map((param) => param.get('id')),
    filter((param): param is string => !!param),
    switchMap(() => this.articlesService.getArticle({ id: this.route.snapshot?.params['id'] })),
    tap((article) => {
      const topicIds: string[] | undefined = article.data.related_topics;
      if (!topicIds) {
        this.relatedTopicIds$.next([]);
        return;
      }
      this.relatedTopicIds$.next(topicIds);
      const articles = topicIds.map((id) => ({ topicId: id, articles: this.getRelatedArticles(id) }));
      this.dependantArticles$.next(articles);
    }),
  );

  readonly getRelatedArticles = (id: string) => {
    return this.articlesService.listArticles({ topicIds: [id], limit: 3 }).pipe(map(({ items }) => items));
  };

  constructor() {
    if (!this.relatedTopicIds$.value) {
      this.relatedTopics$ = of([]);
      return;
    }
    this.relatedTopics$ = this.relatedTopicIds$.pipe(
      switchMap((ids) => forkJoin(ids.map((id) => this.topicsService.getTopic({ id })))),
    );
  }

  openLink(link: string) {
    location.href = link;
  }

  getArticles(id: string) {
    return this.dependantArticles$.value.find(({ topicId }) => topicId === id)?.articles ?? of([]);
  }
}
