<textarea
  #title
  type="text"
  rows="1"
  class="title-input"
  [placeholder]="'i18n.Common.title' | translate"
  (keydown)="handleTextareaChange(title, $event)"></textarea>

<div class="flex flex-col">
  <ngx-editor
    #ngxEditor
    class="block size-full overflow-auto"
    [editor]="editor"
    [(ngModel)]="content"
    [placeholder]="'i18n.TextEditor.placeholder' | translate"
    (contextmenu)="setDialogPosition($event)"></ngx-editor>
</div>

@if (selection) {
  <div
    aria-hidden="true"
    class="w-screen h-screen fixed top-0 left-0 z-50"
    [ngClass]="{
      'top-[180px] left-[50%] ': selection.from === 0 && selection.to === 0,
    }"
    (click)="askForDismiss($event)">
    <div
      class="absolute flex flex-col justify-center gap-2 bg-white shadow-lg border border-slate-200 rounded-lg py-2 max-w-[500] w-fit mt-5"
      [style.top.px]="dialogTop"
      [style.left.px]="dialogLeft"
      (click)="$event.stopPropagation()">
      @if (!aiResponse) {
        <div class="flex items-center gap-2 px-3 w-fit">
          <svg-icon
            key="ai"
            size="md"></svg-icon>
          {{ aiAction && (AssistantResponseActionMap[aiAction] | translate) }}
          <p-progressSpinner
            class="size-5"
            styleClass="size-5"></p-progressSpinner>
        </div>
      } @else {
        <div class="flex flex-col gap-2 w-full">
          <p
            class="px-3 italic text-slate-500"
            [innerHTML]="aiResponse | sanitizeHtml"></p>
          <hr />
          <div class="flex justify-end w-full gap-2 p-3">
            <p-button
              class="rounded-lg border-indigo-500 border text-indigo-500 px-2.5 py-1 text-sm"
              styleClass="!w-fit"
              (onClick)="applySelection('replace'); selection = undefined">
              {{ 'i18n.TextEditorActions.replaceText' | translate }}
            </p-button>
            <p-button
              class="rounded-lg bg-indigo-200 px-2.5 py-1 text-sm"
              styleClass="!w-fit"
              (onClick)="applySelection('addToBottom'); selection = undefined">
              {{ 'i18n.TextEditorActions.addTextBelow' | translate }}
            </p-button>
          </div>
        </div>
      }
    </div>
  </div>
}

<p-contextMenu
  #menu
  appendTo="body"
  [target]="editorRef()?.nativeElement"
  [model]="contextMenuItems">
  <ng-template
    pTemplate="item"
    let-item>
    <div
      tabindex=""
      class="menu-item text-accent-dark"
      (click)="$event.stopPropagation(); item.command($event); menu.hide()"
      (keydown)="$event.stopPropagation(); item.command($event); menu.hide()"
      role="button">
      <div class="text-base flex flex-row items-center w-full">
        <div
          class="flex flex-row items-center gap-2"
          [class.opacity-30]="item.disabled">
          <svg-icon
            [key]="item.icon"
            class="text-accent"
            size="md"></svg-icon>
          {{ item.label }}
        </div>

        @if (item.disabled) {
          <div class="ml-auto rounded-md border border-rest-border text-xs leading-4 font-semibold px-1 py-0.5">
            {{ 'i18n.Common.comingSoon' | translate }}
          </div>
        }
      </div>
    </div>
  </ng-template>
</p-contextMenu>

<p-confirmDialog />
