/* eslint-disable @typescript-eslint/ban-types */

export const handleTextareaChange = (textarea: HTMLTextAreaElement, ev?: KeyboardEvent) => {
  textarea.style.setProperty('height', 'auto');

  requestAnimationFrame(() => textarea.style.setProperty('height', `${textarea.scrollHeight}px`));

  if (ev?.key === 'Enter' && !ev.shiftKey && textarea.value) {
    textarea.blur();
    ev.preventDefault();

    return true;
  }

  return undefined;
};
