import { Injectable } from '@angular/core';

import { D3Graph } from '@swimlane/ngx-graph';
import { Graph } from '../api/backend-connector/models';
import { CustomNode, ARTICLE, TOPIC, LINKLESS_ARTICLE } from '../utils/graph';

@Injectable({
  providedIn: 'root',
})
export class GraphLayoutService {
  private readonly worker = new Worker(new URL('./graph-layout.worker', import.meta.url));

  setGraphData(graph: Graph) {
    const result = new Promise<D3Graph>((resolve) => {
      this.worker.onmessage = (event) => {
        console.log(event);
        resolve({
          nodes: event.data.nodes,
          edges: event.data.links,
        });
      };
    });

    const nodes: CustomNode[] = [
      ...graph.articles.map((n) => ({
        label: n.title,
        color: n.relatedTo.length ? ARTICLE : LINKLESS_ARTICLE,
        ...n,
        isTopic: false as const,
      })),
      ...graph.topics.map((n) => ({
        label: n.title,
        color: TOPIC,
        ...n,
        isTopic: true as const,
      })),
    ];

    const links = graph.articles.flatMap((a) =>
      a.relatedTo.map((t) => ({
        source: a.id,
        target: t.id,
      })),
    );

    this.worker.postMessage({
      nodes,
      links,
    });

    return result;
  }
}
