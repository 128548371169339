/* tslint:disable */
/* eslint-disable */
export enum EventType {
  ExitRoom = 'exitRoom',
  Message = 'message',
  Emotion = 'emotion',
  Topic = 'topic',
  SetContext = 'setContext',
  Feedback = 'feedback'
}
