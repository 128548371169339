@if (topic$ | async; as topic) {
  <div class="flex gap-2 size-full pt-12">
    <div class="flex grow overflow-auto h-full flex-col gap-3 pb-3 px-6 border-r border-r-slate-200">
      <div class="flex flex-col gap-2">
        <div class="text-2xl font-semibold">
          <svg-icon
            class="text-lg w-6 cursor-pointer"
            key="arrow-back-line"
            [routerLink]="['..']"></svg-icon>
          {{ topic.title }}
        </div>
        <div class="flex flex-row gap-2">
          <!-- TODO: Add share button -->
          {{ topic.date | date }}
        </div>
      </div>
      <div>
        {{ topic.shortSummary }}
      </div>
      <div>
        {{ topic.longSummary }}
      </div>
    </div>

    <div class="flex flex-col gap-2.5 w-[400px] shrink-0 pb-6 pl-3 pr-1 border-r border-r-slate-200">
      <div class="font-semibold flex gap-2 items-center text-lg">
        {{ 'i18n.TopicsDetailsPage.sources' | translate }}
        <svg-icon
          class="text-slate-500"
          key="information-line"
          [autoHide]="false"
          [pTooltip]="sourcesTooltip"></svg-icon>
        <ng-template #sourcesTooltip>
          <div class="flex !p-0 text-accent-dark bg-surface text-sm">
            {{ 'i18n.TopicsDetailsPage.Tooltip.sources' | translate }}
          </div>
        </ng-template>
      </div>
      <div class="overflow-y-auto">
        @if (relatedArticles$ | async; as articles) {
          @for (article of articles; track $index) {
            <a
              class="flex flex-col gap-2 py-3 pl-3 pr-5 rounded-lg"
              [routerLink]="['../articles/' + article.id]">
              <div class="flex size-full flex-col gap-2 bg-surface p-3 rounded-xl cursor-pointer">
                <div class="flex gap-2 justify-between">
                  <div class="flex items-center gap-1">
                    @if (article.publisher && publisherNames.includes(article.publisher.name)) {
                      <img
                        alt="publisher_thumbnail"
                        class="rounded-full size-4 border border-rest-border"
                        [src]="thumbnails[castToKey(article.publisher.name)]" />
                    }
                    <div class="truncate">{{ article.publisher?.name }}</div>
                  </div>
                  @if (article.date) {
                    <div class="text-rest min-w-max text-sm font-normal">
                      {{ (article.date | ago).label | translate: { value: (article.date | ago).value } }}
                    </div>
                  }
                </div>
                <div class="text-sm font-normal line-clamp-3 font-article">
                  {{ article.title }}
                </div>
              </div>
            </a>
          }
        }
      </div>
    </div>
  </div>
}
