import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Article, Topic } from '../../api/backend-connector/models';
import { ArticlesService } from '../../services/articles.service';
import { TopicsService } from '../../services/topics.service';
import { AsyncPipe } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { copyContent } from 'src/app/utils/copy-to-clipboard';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';

import * as thumbnails from '../../../assets/thumbnails.json';
import { castToKey } from '../topics-list-page/topics-list-page.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-topic-details-page',
  templateUrl: './topic-details-page.component.html',
  styleUrl: './topic-details-page.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    CarouselModule,
    RouterLink,
    SvgIconComponent,
    TimeAgoPipe,
    TooltipModule,
    TranslateModule,
  ],
})
export class TopicDetailsPageComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly topicsService = inject(TopicsService);
  private readonly articlesService = inject(ArticlesService);

  readonly copyContent = copyContent;
  readonly castToKey = castToKey;
  readonly topicId: string;
  readonly topic$: Observable<Topic>;
  readonly relatedArticles$: Observable<Article[]>;
  readonly publisherNames = Object.keys(thumbnails);
  readonly thumbnails = thumbnails;

  constructor() {
    this.topicId = this.route.snapshot.paramMap.get('id')!;
    this.topic$ = this.topicsService.getTopic({ id: this.topicId });
    this.relatedArticles$ = this.articlesService
      .listArticles({ topicIds: [this.topicId], limit: 100 })
      .pipe(map(({ items }) => items));
  }
}
