/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import * as signalR from '@microsoft/signalr';
import { Subject, Observable, BehaviorSubject, first, firstValueFrom } from 'rxjs';

export const wait = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export class RealTimeConnection {
  private readonly state$ = new BehaviorSubject(signalR.HubConnectionState.Disconnected);

  /**
   * Subjects used to dispatch data received from `on` method and observe them.
   */
  private readonly _onSubjects: Map<string, Subject<any>> = new Map();

  /**
   * List of invoke methods called with their relative data.
   * Useful to re invoke them during after a reconnection.
   */
  private readonly invokeArgs: Map<string, any[]> = new Map();

  constructor(readonly hub: signalR.HubConnection) {
    hub.onreconnected(() => {
      console.log('SignalR Hub reconnected');
      this.invokeAllMethods();
    });

    hub.onclose(async (error) => {
      console.log('SignalR Hub connection closed', error);
      await this.start();
      this.invokeAllMethods();
    });
  }

  async start() {
    try {
      await this.hub.start();
      this.state$.next(this.hub.state);
    } catch (err) {
      console.warn(err);

      await wait(5000);
      await this.start();
    }
  }

  async invoke(methodName: string, ...args: any[]) {
    const key = JSON.stringify({ methodName, args });

    await firstValueFrom(this.state$.pipe(first((s) => s === signalR.HubConnectionState.Connected)));

    const r = await this.hub.invoke(methodName, ...args);
    this.invokeArgs.set(key, [methodName, ...args]);
    return r;
  }

  on<T>(event: string): Observable<T> {
    let subject = this.getOnSubject(event);
    if (!subject) {
      subject = this.getOrCreateOnSubject<T>(event);
      const handler = (...data: any[]) => subject?.next(data as any as T);
      this.hub.on(event, handler);
    }

    return subject.asObservable();
  }

  private getOnSubject(event: string) {
    return this._onSubjects.get(event);
  }

  private getOrCreateOnSubject<T>(event: string): Subject<T> {
    const subject = this._onSubjects.get(event) || new Subject<T>();
    if (!this._onSubjects.has(event)) {
      this._onSubjects.set(event, subject);
    }
    return subject;
  }

  private invokeAllMethods() {
    this.invokeArgs.forEach(async (args) => {
      const [methodName, ...rest] = args;

      if (this.hub.state !== signalR.HubConnectionState.Connected) {
        console.warn('Trying to invoke a method when connection is not ready', methodName, args);
        return;
      }

      await this.hub.invoke(methodName, ...rest);
    });
  }
}
