import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { fadeInOut } from '../../../animations/fadeInOut';
import { ReferenceWidget } from '../../../models/widgets';

@Component({
  selector: 'app-reference-widget',
  templateUrl: './reference-widget.component.html',
  styleUrls: ['./reference-widget.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgClass, TranslateModule],
  animations: [fadeInOut(200, 200)],
})
export class ReferenceWidgetComponent {
  @Input() widget?: ReferenceWidget;

  readonly domSanitizer = inject(DomSanitizer);
}
