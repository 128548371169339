@if (messages$ | async; as messages) {
  <div class="flex h-full w-full flex-row items-start justify-center overflow-hidden text-zinc-900">
    <div class="flex h-full w-full flex-col">
      <header class="flex flex-row items-center gap-2 px-4 text-lg">
        @if (currentUser) {
          <span class="my-auto !font-medium py-1 flex flex-row items-center gap-2">
            {{ 'i18n.Common.aiAgent' | translate }}
            <svg-icon
              class="text-rest cursor-pointer hover:opacity-60"
              size="sm"
              key="information-line"
              [autoHide]="false"
              [pTooltip]="similarTopicTooltip"></svg-icon>
            <ng-template #similarTopicTooltip>
              <div
                class="!p-0 text-accent-dark bg-surface text-sm"
                [innerHtml]="'i18n.ConversationComponent.Tooltip.agent' | translate: { data: 'today' }"></div>
            </ng-template>
          </span>
        } @else {
          <div class="flex h-14 w-full flex-row items-center justify-start gap-2 text-left">
            <div class="flex size-6 animate-pulse justify-center rounded-full bg-gray-500 text-xs"></div>
            <div class="h-5 w-40 animate-pulse rounded-md bg-rationale-accent px-2 py-1 text-white/50"></div>
          </div>
        }
      </header>

      <div
        class="h-full max-h-[calc(100%-178px)] overflow-y-auto"
        [class.!max-h-[calc(100%-200px)]]="isProcessing">
        <div
          #messagesChat
          class="flex flex-col gap-y-6 overflow-y-auto px-4 pt-2"
          [class.h-full]="!messages || messages.at(0)?.type !== EventType.SetContext">
          @for (message of messages.filter(messagesOnly); track $index) {
            <div class="flex w-full flex-col py-1">
              <div
                class="flex w-3/4 items-center"
                [class.ml-auto]="message.senderType === SenderType.User">
                <div
                  class="user-message flex flex-col gap-2 relative flex-1 px-3 py-2 text-zinc-900"
                  [class.other-message]="message.senderType !== SenderType.User"
                  [@fadeInOut]>
                  <div class="flex flex-row items-center gap-2">
                    @if (message.senderType !== SenderType.Agent) {
                      <div class="rounded-full bg-white border border-zinc-200 flex items-center justify-center p-1">
                        <svg-icon
                          key="user"
                          size="md"></svg-icon>
                      </div>
                    }

                    @if (message.senderType === SenderType.Agent) {
                      <svg-icon
                        class="text-accent"
                        key="ai"
                        size="md"></svg-icon>
                    }

                    <span class="text-sm font-bold text-zinc-900">
                      {{
                        message.senderType === SenderType.User
                          ? this.currentUser.username
                          : message.senderType === SenderType.Agent
                            ? ('i18n.Common.aiAgent' | translate)
                            : ('i18n.Common.operator' | translate)
                      }}
                    </span>
                  </div>

                  <app-message-content
                    [content]="message.content"
                    [senderType]="message.senderType"></app-message-content>

                  <div class="absolute right-2.5 top-3 ml-1 flex flex-row flex-wrap items-center text-[10px]">
                    <div class="flex w-fit flex-row items-center gap-2">
                      @if (
                        {
                          label: (message.createdAt | ago).label,
                          value: (message.createdAt | ago).value,
                        };
                        as sentAt
                      ) {
                        <div class="w-fit capitalize-first max-sm:hidden">
                          {{ sentAt.label | translate: { value: sentAt.value } }}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          } @empty {
            <div class="flex w-full h-full flex-col items-center justify-center gap-2 text-sm">
              <img
                alt="Bodoni AI logo"
                src="../../../assets/svg/bodoni-frost.svg" />
              <span class="max-w-[400px] text-base leading-6 font-normal text-rest">{{
                'i18n.ConversationComponent.placeholder' | translate
              }}</span>
            </div>
          }
        </div>
      </div>

      <div class="sticky bottom-0 z-40 mt-auto flex w-full flex-col items-start justify-center gap-1 text-sm">
        @if (hasWidgetToFocus(messages ?? [])) {
          <div
            class="absolute bottom-3 right-2 flex flex-row items-center text-sm"
            [@fadeInOut]>
            <button
              class="rounded-full p-1"
              (click)="focusOnLatestWidget()">
              <svg-icon key="stack"></svg-icon>
            </button>
          </div>
        }

        @if (isProcessing) {
          @if (processingMessage$ | async; as processingPhase) {
            @if (processingPhase !== AssistantResponsePhase.Error) {
              <div class="mb-3 mt-auto flex flex-row items-center px-7 py-1 text-sm ml-4">
                <div class="loading-dots"></div>
                <span
                  class="ml-3 transition-all delay-150 duration-100"
                  [@fadeInOut]>
                  @if (![AssistantResponsePhase.Hung, AssistantResponsePhase.Waiting].includes(processingPhase)) {
                    <strong class="font-bold">
                      {{ 'i18n.Common.smartAssistant' | translate }}
                    </strong>
                  }
                  {{ AssistantResponsePhaseMap[processingPhase] ?? '' | translate }}...
                </span>
              </div>
            } @else {
              <div class="flex w-full flex-row items-center gap-1 px-5 py-1">
                <svg-icon
                  class="text-red-400"
                  key="alarm-warning"
                  size="lg"></svg-icon>
                <span>
                  {{ AssistantResponsePhaseMap[processingPhase] ?? '' | translate }}
                </span>

                <button class="ml-auto flex items-center gap-1 rounded-full px-1.5 py-1">
                  {{ 'i18n.Common.retry' | translate }}
                </button>
              </div>
            }
          }
        }

        <div class="mt-auto flex items-end w-full flex-row gap-2 px-14 pb-8">
          <div
            class="smart-search flex w-full flex-row items-center transition-all"
            [ngClass]="{
              'animate-pulse': areAllContext(messages ?? []),
              'shadow-md box-shadow !shadow-[#6366f1] ': askAIMessage(),
            }"
            [@fadeInOut]>
            <div class="content">
              <svg-icon
                class="text-accent"
                key="ai"
                size="lg"></svg-icon>

              @if (_askAIMessage()) {
                <div
                  class="absolute flex flex-row items-center left-10 text-xs px-2 py-1 font-medium w-32 bg-surface border rounded">
                  <div class="truncate">
                    {{ _askAIMessage() }}
                  </div>

                  <button
                    class="flex cursor-pointer items-center hover:text-red-800 transition-all shrink-0"
                    (click)="removeAskAIMessage()">
                    <svg-icon
                      key="close-fill"
                      class="text-black"
                      size="md"></svg-icon>
                  </button>
                </div>
              }

              <textarea
                #newMessage
                cdkTrapFocus
                type="text"
                rows="1"
                [ngClass]="{
                  '!indent-[8.25rem]': !!_askAIMessage(),
                }"
                [placeholder]="'i18n.ConversationComponent.messagePlaceholder' | translate"
                (keydown)="handleTextareaChange(newMessage, $event)"></textarea>
            </div>
          </div>

          <div class="flex flex-row items-center gap-2 mb-1">
            <button
              class="flex cursor-pointer items-center rounded-full p-0.5 bg-accent hover:text-green-800 transition-all"
              [ngClass]="{
                'opacity-20 cursor-none pointer-events-none grayscale': !newMessage.value,
              }"
              (click)="upsertMessage(newMessage)">
              <svg-icon
                key="arrow-up"
                class="text-white"
                size="xl"></svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}
