@if (article$ | async; as article) {
  <div class="flex gap-2 size-full pt-12">
    <div class="flex h-full grow overflow-hidden flex-col gap-2 pb-3 pr-3 px-6 border-r border-r-slate-200">
      <div class="text-2xl font-article">
        {{ article.title }}
      </div>
      <div class="flex flex-row items-center flex-wrap gap-1.5 px-3 text-sm text-accent-dark">
        @if (article?.authors && (article?.authors ?? []).length > 0) {
          <div class="flex items-center flex-wrap gap-1.5 text-sm italic text-accent-dark">
            {{ 'i18n.Common.of' | translate }}
            @for (author of article?.authors; track $index) {
              <u class="min-w-max">
                {{ author.name }}
                <span [ngClass]="{ hidden: $index === article.authors.length - 1 }">,</span>
              </u>
            }
          </div>
        }

        @if (article.publisher; as publisher) {
          {{ 'i18n.Common.for' | translate }}
          @if (publisherNames.includes(publisher.name)) {
            <img
              alt="publisher_thumbnail"
              class="rounded-full size-4 border border-rest-border"
              [src]="thumbnails[castToKey(publisher.name)]" />
          }
          <div class="underline text-accent-dark capitalize">
            {{ publisher.name }}
            @if (article.date) {
              {{ ',' }}
            }
          </div>
        }
        @if (article.date) {
          <div class="text-accent-dark text-sm">
            {{ (article.date | ago).label | translate: { value: (article.date | ago).value } }}
          </div>
        }
        @if ({ value: false }; as copied) {
          <button
            class="flex items-center min-w-max gap-1 text-xs font-semibold border bg-surface border-rest-border hover:bg-rest-hover rounded px-1 py-0.5 ml-1.5"
            (click)="copyContent(article.link, copied)">
            <svg-icon key="link-line"></svg-icon>
            {{ 'i18n.Common.copyLink' | translate }}
          </button>
        }
      </div>

      <hr />
      <div class="flex flex-col gap-2 px-3 overflow-auto">
        <img
          class="w-full max-w-[500px] lg:max-w-[600px] xl:max-w-[800px] 3xl:max-w-[1000px] m-auto"
          alt="article_image"
          [src]="article.data.top_image" />
        <!-- TODO: add me!
          <div class="text-rest text-xs font-semibold"> {{ article.data }}</div>
        -->
        <div class="pb-6">
          {{ article.summary }}
        </div>
        <button
          class="rounded-full w-fit px-3 py-2 font-semibold text-white bg-neutral-700"
          (click)="openLink(article.link)">
          {{ 'i18n.ArticleDetailsPage.openArticle' | translate }}
        </button>
      </div>
    </div>

    <div class="flex h-full flex-col gap-2.5 w-[300px] shrink-0 pb-6 px-3 border-r border-r-slate-200">
      <div class="shrink-0 font-semibold flex gap-2 items-center text-lg">
        {{ 'i18n.ArticleDetailsPage.similarNews' | translate }}
        <svg-icon
          class="text-slate-500"
          key="information-line"
          [autoHide]="false"
          [pTooltip]="similarNewsTooltip"></svg-icon>
        <ng-template #similarNewsTooltip>
          <div class="flex !p-0 text-accent-dark bg-surface text-sm">
            {{ 'i18n.ArticleDetailsPage.Tooltip.similarNews' | translate }}
          </div>
        </ng-template>
      </div>
      <div class="overflow-y-auto">
        @if (relatedArticles$ | async; as articles) {
          @for (article of articles; track $index) {
            <a
              class="flex flex-col gap-2 p-3 rounded-lg"
              [routerLink]="['../' + article.id]">
              <div class="flex size-full flex-col gap-2 bg-surface p-3 rounded-xl cursor-pointer">
                <div class="flex justify-between">
                  <div class="flex items-center">
                    @if (article.publisher && publisherNames.includes(article.publisher.name)) {
                      <img
                        alt="publisher_thumbnail"
                        class="rounded-full size-4 border border-rest-border"
                        [src]="thumbnails[castToKey(article.publisher.name)]" />
                    }

                    <div class="truncate text-sm">{{ article.publisher?.name }}</div>
                  </div>
                  @if (article.date) {
                    <div class="text-rest min-w-max text-sm">
                      {{ (article.date | ago).label | translate: { value: (article.date | ago).value } }}
                    </div>
                  }
                </div>
                <div class="line-clamp-3 font-article text-xl leading-6">
                  {{ article.title }}
                </div>
              </div>
            </a>
          }
        }
      </div>
    </div>
    <div class="flex h-full flex-col gap-2.5 w-[300px] shrink-0 pb-6 px-3 border-r border-r-slate-200">
      <div class="shrink-0 font-semibold flex gap-2 items-center text-lg">
        {{ 'i18n.ArticleDetailsPage.relatedTopics' | translate }}
        <svg-icon
          class="text-slate-500 block"
          key="information-line"
          tooltipPosition="left"
          [autoHide]="false"
          [pTooltip]="similarTopicTooltip"></svg-icon>
        <ng-template #similarTopicTooltip>
          <div class="!p-0 text-accent-dark bg-surface text-sm">
            {{ 'i18n.ArticleDetailsPage.Tooltip.similarTopic' | translate }}
          </div>
        </ng-template>
      </div>
      <div class="flex h-full flex-col grow">
        @if (relatedTopicIds$ | async) {
          @if (relatedTopics$ | async; as topics) {
            @for (topic of topics; track $index) {
              <div class="flex flex-col gap-2">
                <div
                  class="flex size-full flex-col gap-2 pb-1 pt-3 px-3"
                  [ngClass]="{ 'border-b border-slate-200': $index !== topics.length - 1 }">
                  <a
                    class="text-xl font-semibold truncate cursor-pointer"
                    [routerLink]="['../../' + topic.id]">
                    {{ topic.title }}
                  </a>
                  @if (getArticles(topic.id) | async; as articles) {
                    @for (article of articles; track $index) {
                      <div
                        class="w-full flex items-center gap-2 cursor-pointer"
                        [ngClass]="{ 'border-b border-slate-200': $index !== articles.length - 1 }">
                        @if (article.publisher; as publisher) {
                          <img
                            alt="publisher_thumbnail"
                            class="rounded-full size-4 border border-rest-border"
                            [src]="thumbnails[castToKey(publisher.name)]" />
                        }
                        <a
                          class="text-sm w-full grow truncate font-article"
                          [routerLink]="['../' + article.id]">
                          {{ article.title }}
                        </a>
                      </div>
                    }
                  }
                </div>
              </div>
            }
          }
        }
      </div>
    </div>
  </div>
}
