<div class="max-h-[inherit] flex flex-col overflow-hidden rounded-lg bg-white shadow py-1 border border-rest-border">
  <div class="shrink-0 flex gap-1 text-sm text-rest font-semibold uppercase border-b border-rest-border empty:hidden">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="flex grow flex-col gap-1 p-1 overflow-auto">
    <ng-content></ng-content>
  </div>
  <div class="flex w-full items-center justify-center shrink-0 text-sm font-semibold text-rest empty:hidden">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
