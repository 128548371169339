<button
  class="flex h-9 w-full items-center justify-start gap-2 overflow-hidden rounded-lg p-2 text-sm text-rest hover:bg-teal-100"
  (click)="isSelected.set(true)">
  <div class="truncate mr-auto">{{ label() }}</div>
  @if (isSelected()) {
    <svg-icon
      class="text-accent"
      key="check-line"></svg-icon>
  }
</button>
