/* tslint:disable */
/* eslint-disable */
export enum OperationType {
  Unknown = 'unknown',
  Add = 'add',
  Remove = 'remove',
  Replace = 'replace',
  Move = 'move',
  Copy = 'copy',
  Test = 'test'
}
