export interface Message {
  id?: string;
  user: User;
  content?: string;
  sentAt?: string;
}

export interface User {
  id: string;
  username: string;
  avatarUrl?: string;
}

export enum AssistantResponsePhase {
  Analyzing = 'analyzing',
  Searching = 'searching',
  Synthesizing = 'synthesizing',
  Writing = 'writing',
  Hung = 'hung',
  Waiting = 'waiting',
  Error = 'error',
}

export const AssistantResponsePhaseMap: { [status in AssistantResponsePhase]?: string } = {
  analyzing: 'i18n.AssistantPhase.analyzing',
  searching: 'i18n.AssistantPhase.searching',
  synthesizing: 'i18n.AssistantPhase.synthesizing',
  writing: 'i18n.AssistantPhase.writing',
  hung: 'i18n.AssistantPhase.hung',
  waiting: 'i18n.AssistantPhase.waiting',
  error: 'i18n.AssistantPhase.error',
};

export type Action = 'write_better' | 'grammar_check' | 'synthesize' | 'simplify';

export const AssistantResponseActionMap: Record<Action, string> = {
  write_better: 'i18n.AssistantActionWait.writeBetter',
  synthesize: 'i18n.AssistantActionWait.synthesize',
  grammar_check: 'i18n.AssistantActionWait.grammarCheck',
  simplify: 'i18n.AssistantActionWait.simplify',
};
