@if (widget) {
  <a
    class="flex h-full flex-col gap-1 rounded-md px-2 text-xs bg-surface"
    [href]="widget.link"
    [ngClass]="{
      'cursor-default pointer-events-none touch-none': !widget.link,
      '!cursor-pointer !hover:opacity-50 !transition-all': widget.link,
    }">
    <div class="flex flex-col py-1 relative border-b border-b-rest-border -mx-2 px-2">
      <span class="absolute top-0 font-article text-accent-dark line-clamp-2 text-xs font-semibold mb-0">
        {{ widget.publisher }}
      </span>

      <span class="font-article text-accent-dark line-clamp-2 text-xl text- font-bold mb-0">
        {{ widget.title }}
      </span>
    </div>

    <div
      class="font-article py-1 -mx-2 px-2 flex grow flex-col items-center justify-center overflow-hidden rounded-t-md">
      <div class="line-clamp-3">
        {{ widget.body }}
      </div>
    </div>
  </a>
}
