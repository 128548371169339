import { AsyncPipe, NgClass } from '@angular/common';
import { Component, computed, effect, ElementRef, inject, input, model, viewChild, signal } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { D3ForceDirectedLayout, Graph, NgxGraphModule } from '@swimlane/ngx-graph';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject, debounceTime, delay, filter, firstValueFrom, Observable, of, switchMap, tap } from 'rxjs';
import { ArticleNode, Graph as beGraph, PeriodResponse, TopicNode } from 'src/app/api/backend-connector/models';
import { TooltipComponent } from 'src/app/components/tooltip/tooltip.component';
import { TimeAgoPipe } from 'src/app/pipes/time-ago.pipe';
import { GraphLayoutService } from 'src/app/services/graph-layout.service';
import { GraphService } from 'src/app/services/graph.service';
import { colors, CustomNode, ARTICLE, TOPIC, EDGE, LINKLESS_ARTICLE, RELATED_NODES } from 'src/app/utils/graph';
import { SingleChoicePanelComponent } from 'src/app/widgets/single-choice-panel/single-choice-panel.component';
import { structuredItem } from '../structured-panel/structured-panel.component';
import { toSignal } from '@angular/core/rxjs-interop';

class MyForceLayout extends D3ForceDirectedLayout {
  constructor(
    private readonly layoutService: GraphLayoutService,
    private readonly graph$: Observable<beGraph>,
  ) {
    super();
    this.outputGraph$ = this.myOutput$;
  }

  readonly myOutput$ = new BehaviorSubject<Graph>(this.outputGraph);

  override run(graph: Graph): Observable<Graph> {
    this.inputGraph = graph;
    this.d3Graph = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      nodes: [...this.inputGraph.nodes.map((n) => ({ ...n }))] as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      edges: [...this.inputGraph.edges.map((e) => ({ ...e }))] as any,
    };
    this.outputGraph = {
      nodes: [],
      edges: [],
      edgeLabels: [],
    };
    this.settings = Object.assign({}, this.defaultSettings, this.settings);
    if (!this.myOutput$.value) {
      this.myOutput$.next(this.outputGraph);
    }

    return this.graph$.pipe(
      switchMap((g) => this.layoutService.setGraphData(g)),
      tap((g) => {
        const d3G = (this.d3Graph = {
          nodes: g.nodes.map((n) => ({ ...n, meta: {} })),
          edges: g.edges.map((e) => ({ ...e })),
        });
        const myG = this.d3GraphToOutputGraph(d3G);
        this.myOutput$.next(myG);
      }),
      switchMap(() => this.outputGraph$),
    );
  }

  override updateEdge(): Observable<Graph> {
    return this.outputGraph$.asObservable();
  }
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrl: './graph.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgxGraphModule,
    OverlayPanelModule,
    SingleChoicePanelComponent,
    SvgIconComponent,
    TooltipComponent,
    TooltipModule,
    TranslateModule,
  ],
})
export class GraphComponent {
  private readonly graphService = inject(GraphService);
  private readonly timeAgo = inject(TimeAgoPipe);
  private readonly translate = inject(TranslateService);

  readonly period = input.required<PeriodResponse>();
  readonly query = input<string | undefined>();
  readonly selectedPublisher = input<structuredItem[]>();

  readonly selectedNode = model<CustomNode | undefined>(undefined);
  readonly relatedArticles = model<ArticleNode[] | null>(null);
  readonly relatedTopics = model<TopicNode[] | null>(null);

  readonly tooltipComponent = viewChild<ElementRef>('tooltipComponent');
  readonly tooltip = signal<{ mode: 'article' | 'topic'; id: string; title: string; body?: string } | null>(null);
  readonly center$ = new BehaviorSubject<boolean | null>(null);
  readonly graph$ = new BehaviorSubject<beGraph | null>(null);
  readonly graph = toSignal(this.graph$);
  readonly defaultTopics$ = new BehaviorSubject<(TopicNode & { top: number; left: number; color: string })[]>([]);
  readonly defaultTopics = toSignal(this.defaultTopics$);

  readonly layout = new MyForceLayout(
    inject(GraphLayoutService),
    this.graph$.pipe(filter((x): x is NonNullable<typeof x> => !!x)),
  );

  readonly EDGE = EDGE;

  readonly nodesAttrs = computed(() => {
    const graph = this.graph();
    if (!graph) {
      return new Map();
    }

    const topics = graph.topics.sort((a, b) => b.articlesCount - a.articlesCount);
    const nodes = [
      ...topics.map((x) => [x.id, { color: this.getTopicsAttr(x.id) }] as const),
      ...graph.articles.map((node) => [node.id, { color: this.getArticlesAttr(node) }] as const),
    ];

    return new Map(nodes);
  });

  getArticlesAttr(node: ArticleNode) {
    const relatedArticles = this.relatedArticles()?.map(({ id }) => id);
    const publisherColors = new Map(
      this.selectedPublisher()
        ?.filter((x): x is typeof x & { color: string } => x.isSelected && !!x.color)
        .map((x) => [x.id, x.color]),
    );

    return (
      publisherColors.get(node.publisher?.id ?? '') ??
      (relatedArticles?.includes(node.id)
        ? RELATED_NODES
        : !this.selectedNode()?.id && node.relatedTo.length
          ? ARTICLE
          : LINKLESS_ARTICLE)
    );
  }

  getTopicsAttr(id: string) {
    const defaultTopic = new Map(this.defaultTopics()?.map((x) => [x.id, x.color])).get(id);
    const relatedTopics = this.relatedTopics()?.map(({ id }) => id);
    let res = relatedTopics?.includes(id) ? RELATED_NODES : defaultTopic;
    if (!res) {
      res = !this.selectedNode()?.id ? TOPIC : LINKLESS_ARTICLE;
    }
    return res;
  }

  constructor() {
    this.center$.next(true);
    effect(async () => {
      const period = this.period();
      if (period) {
        const nodes = await firstValueFrom(this.graphService.listNodes({ periodId: period.id }));
        this.graph$.next(nodes);
        await firstValueFrom(this.layout.outputGraph$.pipe(debounceTime(1500))).then(() => {
          const defaultTopics = nodes.topics
            .sort((a, b) => b.articlesCount - a.articlesCount)
            .slice(0, 10)
            .map((n, i) => ({ ...n, top: 0, left: 0, color: colors[i] }));

          this.defaultTopics$.next(defaultTopics);
          this.recomputeState();
        });
      }
    });

    effect(async () => {
      const node = this.selectedNode();
      await firstValueFrom(of(true).pipe(delay(150))).then(() => this.recomputeState());
      if (!node) {
        this.relatedArticles.set(null);
        this.relatedTopics.set(null);
      }
    });
  }

  recomputeState() {
    for (const topic of this.defaultTopics$.value) {
      const elementRect = document.getElementById(topic.id)?.getBoundingClientRect();
      topic.top = (elementRect?.top ?? 0) - window.scrollY;
      // Necessary since position is absolute
      topic.left = (elementRect?.left ?? 0) - window.scrollX - 210;
    }
  }

  async selectNode(node: CustomNode) {
    this.selectedNode.set(node);
    if (node.isTopic) {
      const articles = this.graph$.value?.articles.filter((a) => a.relatedTo.map(({ id }) => id).includes(node.id));
      this.relatedArticles.set(articles ?? null);
      this.relatedTopics.set(null);
    } else {
      const topicsId = node.relatedTo?.map(({ id }) => id);
      const topics = this.graph$.value?.topics.filter(({ id }) => topicsId?.includes(id)) ?? null;
      this.relatedTopics.set(topics);
      this.relatedArticles.set(null);
    }
  }

  async clickOnTooltip(nodeId: string) {
    const graph = this.graph();
    const article = graph?.articles.find(({ id }) => id === nodeId);
    const topic = graph?.topics.find(({ id }) => id === nodeId);

    if (article) {
      await this.selectNode({ ...article, isTopic: false, label: article.title });
    }
    if (topic) {
      await this.selectNode({ ...topic, isTopic: true, label: topic.title });
    }
  }

  onNodeHover(event: MouseEvent, node: CustomNode) {
    const tooltipComponent = this.tooltipComponent();
    if (tooltipComponent) {
      tooltipComponent.nativeElement.style.left = `${event.pageX - 220}px`;
      tooltipComponent.nativeElement.style.top = `${event.pageY}px`;

      if ('articlesCount' in node) {
        if ((this.defaultTopics$.value ?? []).map(({ id }) => id).includes(node.id)) {
          return;
        }
        this.tooltip.set({ mode: 'topic', id: node.id, title: node.label ?? '-' });
      } else {
        const article = this.graph$.value?.articles.find(({ id }) => id === node.id);

        const date: string | undefined =
          article?.date && article.date !== ''
            ? this.translate.instant(this.timeAgo.transform(article.date ?? '').label)
            : undefined;
        const publisher = article?.publisher?.name;

        const body = publisher ? `<u>${publisher}</u>${date ? ', ' + date : ''}` : date;
        this.tooltip.set({ mode: 'article', id: node.id, title: node.label ?? '-', body });
      }
    }
  }

  markUnfocusedLink(target: string, source: string) {
    if (!this.selectedNode()?.id || this.selectedNode()?.id === target || this.selectedNode()?.id === source) {
      return false;
    }
    return true;
  }
}
