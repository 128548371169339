<div class="size-full divide-x flex flex-row pt-12 items-center justify-center overflow-hidden gap-4">
  <div class="flex flex-col size-full justify-start pb-8">
    <app-text-editor
      class="px-14 py-4"
      [globalAction]="globalAction()"
      (askAIMessage)="askAIMessage.set($event)"
      [(content)]="content"></app-text-editor>
  </div>

  <div class="flex flex-col size-full items-center justify-center">
    <app-conversation
      class="size-full"
      [askAIMessage]="askAIMessage()"></app-conversation>
  </div>
</div>
