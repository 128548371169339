import { ColorPickerModule } from 'primeng/colorpicker';
import { NgClass } from '@angular/common';
import { Component, computed, input, model, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from 'primeng/autofocus';

import { SimplePanelComponent } from '../simple-panel/simple-panel.component';
import { StructuredItemComponent } from '../structured-item/structured-item.component';
import { IconFieldModule } from 'primeng/iconfield';

export interface structuredItem {
  id: string;
  name: string;
  color?: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-structured-panel',
  templateUrl: './structured-panel.component.html',
  styleUrl: './structured-panel.component.scss',
  standalone: true,
  imports: [
    AutoFocusModule,
    ColorPickerModule,
    FormsModule,
    IconFieldModule,
    NgClass,
    ReactiveFormsModule,
    SimplePanelComponent,
    StructuredItemComponent,
    SvgIconComponent,
    TranslateModule,
  ],
})
export class StructuredPanelComponent {
  readonly title = input<string>();
  readonly items = model.required<structuredItem[]>();

  readonly query = signal('');
  readonly filteredItems = computed(() => {
    const query = this.query().toLocaleLowerCase();
    return this.items().filter((x) => x.name.toLocaleLowerCase().includes(query));
  });

  updateItem(item: structuredItem) {
    this.items.update((items) => items.map((i) => (i.id !== item.id ? i : item)) ?? []);
  }
}
