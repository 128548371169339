<div class="flex size-full items-start">
  <nav class="sidebar flex h-full w-56 shrink-0 flex-col gap-8 overflow-y-auto border-r pl-12 pt-12 pb-6">
    <h1 class="text-primary text-2xl uppercase leading-9">
      <img
        src="./assets/svg/bodoni-prototype-logo.svg"
        alt="Bodoni Prototype"
        class="w-[180px] h-10" />
    </h1>
    <div class="flex flex-col grow gap-1.5">
      <a
        class="sidebar-link"
        routerLinkActive="active"
        [routerLink]="['/explore']">
        <svg-icon
          class="text-slate-500"
          key="compass-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        {{ 'i18n.SidebarMenu.explore' | translate }}
      </a>
      <a
        class="sidebar-link"
        routerLinkActive="active"
        [routerLink]="['/news']">
        <svg-icon
          class="text-slate-500"
          key="news-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        {{ 'i18n.SidebarMenu.news' | translate }}
      </a>
      <a
        class="sidebar-link"
        routerLinkActive="active"
        [routerLink]="['/coeditor']">
        <svg-icon
          class="text-slate-500"
          key="quill-pen-line"
          routerLinkActive="!text-accent bg-teal-50"></svg-icon>
        {{ 'i18n.SidebarMenu.coeditor' | translate }}
      </a>
      <div
        class="mt-auto max-w-40 text-sm leading-5 font-medium text-rest [&_a]:underline"
        [innerHTML]="'i18n.SidebarMenu.about' | translate"></div>

      <div class="!gap-4 text-rest text-sm mt-12">
        <!-- <svg-icon key="logout"></svg-icon>
        {{ 'i18n.SidebarMenu.quit' | translate }} -->
        {{ 'V 0.001' | translate }}
      </div>
    </div>
  </nav>
  <router-outlet class="hidden" />
</div>
