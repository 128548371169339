import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ago',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  private readonly translate = inject(TranslateService);

  transform(value: string, now: number = Date.now()): { label: string; value?: number } {
    const valueDate = new Date(value);

    const seconds = Math.round((now - +valueDate) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);

    if (seconds < 45) {
      return { label: 'i18n.TimeAgo.fewSeconds' };
    }
    if (seconds < 60) {
      return { label: 'i18n.TimeAgo.minuteAgo' };
    }
    if (minutes < 45) {
      return { label: 'i18n.TimeAgo.minutesAgo', value: minutes };
    }
    if (minutes < 60) {
      return { label: 'i18n.TimeAgo.hourAgo' };
    }
    if (hours < 24) {
      return { label: 'i18n.TimeAgo.hoursAgo', value: hours };
    }
    if (hours < 48) {
      return { label: 'i18n.TimeAgo.dayAgo' };
    }
    if (days < 7) {
      return { label: 'i18n.TimeAgo.daysAgo', value: days };
    }
    if (valueDate.getFullYear() === new Date(now).getFullYear()) {
      return { label: valueDate.toLocaleString(this.translate.currentLang, { month: 'long', day: 'numeric' }) };
    }
    return {
      label: valueDate.toLocaleString(this.translate.currentLang, { year: 'numeric', month: 'long', day: 'numeric' }),
    };
  }
}
