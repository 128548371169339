{
  "Corriere della Sera": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAARVBMVEVHcEwCVHoAR3EAQGsAP2sAPmoEU3kAT3ZmgpultcPH09va4ugBRnCWqbn3+vz///8HUXcAM2SxwMzu8/YfUnc/ZoZ+lam8iaFuAAAACHRSTlMAJYnM5v5CZ2LvnRIAAAEwSURBVHgBbZPRmsUQDIS1xKGiGtT7P+qGaLfds/P1ys9MRKpuLas2AEavVv3Vx4DzWwibd2A+L7RojHua2v2hl19mwU005cDejKKsbTFuKeWci6dJF/ADhQqIcDpCKsmDOGsnjI4h5K87m1EnSh52AIOP3bXXbCQwA7OYKzOJ8aYnykHHrIk5BLGyapXEdPIil1oY1rl9VVpKLexKhc0I6EwSpJXZ5IoMa29PKWUXuBk1AyL2yJcCDCiRmL/gtJ1F7q21mG5bKSiQREZCuNyznlfJHOme7nIVO5rgUZoG4n41QRnf/akfKQ3FvSub3vgjsQ4WADO5kDRenuzxYHUX1szjsUMlRKSzTFNYrjHxMiQthiuQ7GPA0ksN7HM0q99v5KtZ/h/q7PA91OL9/Tv8AI/tINZqHDKsAAAAAElFTkSuQmCC",
  "il Fatto Quotidiano": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAA0lBMVEX09PT29vb7+/vw8PB2dXWDgoKKiYrf39/Gxsa9vb0AAAARDA6ysrLDxcWanp4IFRbS1dX8///4+/v4+Pj///8XExQgHR7p29u7eXl8AABxAAChPz/Mp6e2ra3Ajo6HAAD37e1BQkKWICDq6uqLAABWKCjZ2NmNAAAqAAC9iIibeXmVAAA7NTZTUVKko6SqXFxyNDQRFheTampmAAApHh+YJycxLi8bHyBdFxhSFhfRsbEeGhsRICGuaWnFmZmfNTWtl5fh0M+jSUjZv75fXl5mQEFK40PBAAABiElEQVR4AbXP1YKdMBRA0ROCJReLXBe0OePuLvT/f6lo25n32TgLSeCHI9Z/kW9Gbcd1XafNdbxvCj7jEx74fhjFPBESupQajno645YS88UyXq03sn3Z2voagBACLSqxS9dZHm7SomzUr345lqLG61DscM/TTqD3K6QEDg6PeF6a4xMPGpxXFTeqLEmW4qmAg7N4MuGh753Q2ez84vKKm/Yf4hrTEjx27OZH3NU0uJnd3t3fNggA8hSrByAWSzxNGfHM4+zp8HA54DPiSzt9w1jg6oDNGsTDPaOkJAOC8kt9wm1OA/70ivhG3182VHxg9dkiC04cfsNP9A3fb25tiofTNMN2QK0qy9zUk5zZvLm3j0qK1wKrhYQ2ohLbn3I94/bvJV4LkLsKC9KbF9VBxPpe0vRTqGe8hhFPAqeuWWkppeR8v0ixQJREdqqS+riu7aSbIZFCiE1VbfQ1dFmsPj52KVPQR+Yp4scCRnWYSRSMiUWaphRGTYwi8C85f5f/rsn/1F//VH8AhKcqIe6f7sIAAAAASUVORK5CYII=",
  "Il Messaggero": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAAAAABXZoBIAAABVElEQVR4AW3SIYzqQBhF4avXJwgs3mDWYNYHiUJWrmtSh081bu2KelNTi8JVjSOpqatugrjn9U8h7NvtSeaaLyMmGbGYbUCx42CA3ox3PO2II5l7JimrmvPW/aoAhvc9YCw46VGe3qQzt7WUYwhcP2xX5RtJ5Wknqagxgg+d6u8yTrrupLxrDtIKQKYRUaVz2xXS6XZtpM0DaUbgS4G5AusZHQiG4xJiY0O2hA55Yl9M2D3QzMjQ7gPro5TVPxBDJSlQkV4YNmoJQbZJ2mzeAjXtSj+fYvpmGI6B7adUtE9ERCw+BXkKE/j1P9qCIHyQ0vX3TeZGqeIP2pDSyG2AS+AhBaoHy6SttC4noxwOkt6vZ0nbqkNYc9n3Z9GlvbRJ6SSpBtHq2XaIz7XugVwNWHTSRzV9k7K+A/RhuI0VZLrwzLxyIPUFG4MdE2dONoCxiQVe8w949vtQ3cTOIAAAAABJRU5ErkJggg==",
  "Libero": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAflBMVEX////u8vaes8phhq05a50uZptXf6mQqMPY4eqxwtQAPIQATIwAUI4ATo0ASIoAP4bQ3OcARokAUo8APYXI1OEAQYYRWZO/zdyjts1sjbJCcqErZJoARIjh6O9Kd6Tl7PF5lre2xtj6/fSTvWR0rCiZwXCPu155rzZ/skF3ri8zA9I/AAAA0UlEQVR4AWIYSABghC6QKwahKIDGDSvE3W3/G+xLZz5Sv1E4uO24nh+E0bcYJwhjQpn3hd8AufgISR3TMiERwkJjutzXUSSZhi41ERdaxxSbKPJSYsXJJ0S1xBpJ/DpuQ74g/x+66AsKiQ4lLWCnkLgS+xRT2BBn+RMCyGNLZiBdBp/xiR2AImVPV2G/ChMSbF7WbV9ex5AQPs1ZlpVz1eKuto7zOndLKeJd13FOMYPj2a7ruuXIUZsjDEEdmqFo9IRkU0t54QJBlvWWc76PzgAARwgWq3VyDLsAAAAASUVORK5CYII=",
  "La Stampa": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAdVBMVEUHOGAAM10AK1gAJ1YAKVcALloANl8AMVwAJFRCX3yLmqqrtcG5wMmjrrt7ip0AH1I2VHNccYno6+/////HztbN1NsALVnY3uP7/P0AE00AHVGzvMdbcYoAGU+DkKH09/iZprQTP2YAAEdxg5hsf5QdRWkAD0vtoEt6AAAA1klEQVR4AcXQBRaEIBQFUBrs7s79L3HM4/BdgM+ES6NvgwmljAskOIYklWFatuMiz3cDoDQIozNWHCVKs9Td68M4OzzWUeZbnVmoUlQvlPXeodn+RONA5O2OQcGkQF4H5pTBjlHW17JszKQn+pzRndAZRt0QaaMnlqcj8qb80UrfiiBeM9vhrT7+M2xsFYSWpLaOAcwUoKOOE1527AnAyKD777qP3XKIUcs81fT73yBeGOVZfKypJQjgFF9L7Sawk8V0mbe4jl31Nd0n1JTz/cUoSzH6ND8GPhNa5DSaGwAAAABJRU5ErkJggg==",
  "il Giornale": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAZlBMVEX////8/PzFxcWYmZh+fn6Kioq2trby8vIAAABgYGDm5ubNzc0ODw4REhGnp6c/Pz8FBwW7u7tnZ2fi4uLd3d0MDQzX19c4ODiQkJDBwcFsbWyurq4aGxpSUlJNTU1ZWVktLi0lJiUCdFh/AAAA5klEQVR4AaXPBZLDMBBE0bFFbWYO3v+SO5FpNklhfmHXM0j0e0GotDbWfSGnsRXFZIFEWAik2c55UUqsmDLUYdNWNTqUmcAGGZslX48ukziUPMd9tZAY8upKOhohMGKEojOkJ/o/9gI1Dmw9kqg1pqG1ibHr6HuWsSy2oep5rw7fscbZwrti7C4bmusVXfb6z/U28e7fDhSkrAi24TEmEvdOsY8bK5YT7x7lcYcTi1Igda+fTPtyyCSGfvbnVyXS6LdOnIvtAx0EcjmY4SvxnMEoau7YU0RPMMrcoqP7rBLiAufot/4AAggPzbxJFIgAAAAASUVORK5CYII=",
  "Il Sole 24 Ore": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAk1BMVEX15dX46Nj769qmnpavr6+oqKiFfXbbzL2uo5l8d3J1cm5TVFVtbW7///9eXl6mo6EAAAC8sKRwcnTHx8fMv7Lq2snAwcKVi4GspqB6e3uKioqYmJgxLCj/7t3q6uoUFRaOjo7HuasLDQ5lXVUoKizz8/NPT08kIiBAQEBdW1iUjYc3ODlsZmCbkYfX2NlhYWHv39BsSxQVAAAA70lEQVR4AWIYpADAFVkgMQwDMbDhktJLOcyM/39dnXqCGtJ4fSwIgijs7CxJVlRN/LOjJJ245RLPlyugH8UR3gCZtDlWMO5ED4Ce7On1HuFnDhW/pmzZgOm4LKu9gyyTZwEKyyba/g5KDAYjNMSnSVsohA/YCnAlw7WjaIZcbpzQAymRZlxpjpwTxwRklL9sInWGc2yAC5FxM4uyAt51465Y2+mZqcRny7JM4HFPmqXdtgP+2XLiNYleE2OZOGwPmioDqe5rc+BFH9UX1Ipu3zN3rx1hooNPXGOTObevCQrDk0sTZr85NtfiD78NRgAAjtUU8lmslpMAAAAASUVORK5CYII=",
  "Il Tempo": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAXklEQVR4AWMYNOA/mZiVUgNE0A34DsTMYIypmAUqfg0hhumFb3i8xQgVv4HNAFEoFiHCACG4egIA0wCiwagBkUCchMWAJDBGBSzYDPiGJ+X9JcYFX4H4Dw78j+q5EAA39F1GBZ04fAAAAABJRU5ErkJggg==",
  "Il Secolo XIX": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAY1BMVEUAOGMAN2IALV0AM2BrhZ4aSHAAJ1lAYoLT3OP///+brr4CPGitvMkxV3uOo7W3xdHd5Op8k6kAKVvr7/P8/f72+fpZdpHF0NkeSXD1+Pr////g5+yYrr+yws52jqQAOGNhfpilfERlAAAAIXRSTlP/2P/////////////////////Y///////N2NjY2NjY2tj+GCGSAAABMUlEQVR4AZ2RBZLEMAwEHSnmMCe39P9XnsfLDCpSTYslXljyG6TnkCmVTyArbaxTDyH5zAbLk0eoKCvAurlFzGkgBSAnN4h8Cd241nb+GpLMjANsvbHd1bTs69ZaZyLtbX8BmfO9nA5ZB6en5rzZ6MbYjkkNMaycjs1cqFNb2ECCRIHkOSIxlDE0Ra6JU/fBW4KDKg6RhdLQahLCh0LZIjhHVlUGmpJyGEkyy8629Cfo0KkAZNkG3ynArlqFjhXgWALuQzutcmjrAIcq4jinYIPIPL5lwWjCx3lQLoTC67BKuzmdAJLRitX+8qV1/nQhArWd89JYWJqr88uQgCV7U2CzgVmIE0TmEMcoqr5mgnaGJtTc/1JLoEsYV8gqrEF80LYiibad/pZlmVfzrklONv0DNrITOYn+rd4AAAAASUVORK5CYII=",
  "La Verità": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAUVBMVEVHcExfhp9fhqBehp9ni6MaGhoSEhIVFRV0eX0+Pj4eHh5chJ5ih6APDw8AAAArKythhp9ghp+lpaViiKArKytZYmljh6AmJiYjIyMpKSlni6PqNgKDAAAAG3RSTlMA0tznYuDj9hdJ1f93//9dnvMJ7G0qf4KhdkoAtAJ2AAAAsklEQVR4AdXRhQ3EMBBE0QkbN4z9F3qTjY8ryBcEnmTEvcrys6LEWVWfNQa24dPBXwXFKJpBK6wBOlJvoA2ViFQDABPF8RmII1ITMYLNIi0fyycOwiyAVWT4RTjipk+HP9yJjS5t/0cjbIYVMf+o407YpMY/6rgbnKzfuHRHGrfBOfQnmnRMFdXStZCw9L4EW4mOI2t6fGVZZt4fab3MqvmPFHXc+HllWnEtYq+resP9egAX3QyQh0KZvgAAAABJRU5ErkJggg==",
  "Il Foglio": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAAAAABWESUoAAAA3ElEQVR4Ac3TIQyEIBTGcXqyp+vpeqKn68lkttrYqGbry2YrG8lMptI30uXv9qbHmCK22/3Dm7IfBDYEbvoFCKoQZcCKQjID0dp+W+2ttdQ2/PnMAHhki7HlnxJIx7Z3AI8KCMoCVAGjUECsgCcDvC4BCQZcETglaoCrgkY+6kACtkkgFAHcF/jOFwHaHcDzMOMJEIOUXk/ASdq2GsCNnTYHkBocgFUDFyB0PCdzCczEsw87cIJrFqSmNb7hB3hAqMM9rzPvjfTGMocFECSzCJg1AD0FwA185j+8zQ+YhQLNAWC9XwAAAABJRU5ErkJggg==",
  "Secolo d'Italia": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAYFBMVEX////s8PSfs8dIb5UPQ3UaTHsYSnogUH5egaJwj62lucv2+Pp8mLMOQnRWep22xtWLpLwnVYIrWYXU3eZniKdAaZHj6e81YIrJ1eCkuMuOpr52lLDN2OKXrcO9y9mxwdIBAwShAAAAnklEQVR4ASXNBQLEIAwEQJxrw0Ld5f+/vKQsPpjiaGOdD/FnmlZJKAXkUro+uIYEjMcw8lgGTAKUgVnLTuc+0AMQi8CyVlgBDGnjQ7sRoASOi/bYiJRknFHTn4uqMg1VXP1FK7ruHB2LfxjevAiPpwdcx9Pd30pCk4M7eNKGYfvkdXJFAFaEksNPfxDdejwleZdHxbma97bBe8ydvP4HrRIJWKU59ewAAAAASUVORK5CYII=",
  "Byoblu": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAASFBMVEVHcEyFmrGBlq2OpLi1ws+nt8cAABkAAAAzWIDDzNaYqryfscQUNl////8BKVfx9Pba3uIiPmTe4+g/VHGAi5ypsLiZoa1gb4WwEzEqAAAADHRSTlMAk1CZ8c0KASz3mJkIkkO2AAABVElEQVQ4jXWTi5KDIAxFo4IQVwEB8f//dEl4KLttpp1O5XjzIBcUqBygAPS8SSFQyG3WAPw8f6GFXtDbg8N6XDSMsU5YTyuD0/o+13I4ZkR2EQVa+EghBkJwJfy+ONxuKOIgIqrGmvXduVMYN2ZZWWLK+RuAYx0TJ6CHn4EDKcliB8B6/5JYqkAD9sC1huQeidm/gRrmTGUwfobNfgAyEvhFu4E8PgKZ4DQSxBcgEzys78BuLgZwBIyhT4nb/lcwd858NQ0kYCzy5J6S6Tnk2Ga9ThfKv0RtDoPKj95ApEGVUfsK3GXAe8PptviyjtBas6+KBV1WlYi9txRby8Ehbx0tzOGf+TTUxLwwqq5cb+0dTtbV1zQse/8hzFXXWtHak8ZAmLOcV2eycWzaO2LClY3TvalUsZ6PgYs8QxKD9VQ3r8WLAn80NFs9v2x/zPb/eewP6hd3+SrZQrF/KAAAAABJRU5ErkJggg==",
  "The Guardian": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAdVBMVEUFKWIAJ2EAGlsAEFgADVcAIl8AH11/iaK8ws7R1d6YobVrd5UAB1YAFloNLma2vMr////M0NoAGFoAJWAvRHJndZTf4ugAAFTu8PRLXINTY4cAAFCiqLokPW7a3uUcN2sACVZEVX6eprmIkqlgbY6LlawhO22nLDbKAAAA8UlEQVR4Aa3QBbaDMBAF0BDF0g7uLvtf4mdIvTn6+7DhXPSRX8ShjFMipGMhLpTr+SwIQv1p9HIFiGicpAA+E2/GMwDIi4SQ+JjKNHkxpuBIJXEurgB+/DRZo+WNOSvyN+QlYqvNmehekSrAZJKYaOXTB4b9icPjB+nTCIMzDPEzSXpaOdpQVAZD7EmbxBZ0hmtrQj8fi/Pk4exNjwL1ywdxH0fFPn/lgqhnHBf6QLqcuEobOvqsb9Y2JHI1xVuRhBscwU80uL0iKRT+QHPHSpDX6DQCcAuq2+NwkZ8Nh7vv9duo5sFWchKPnBKpE/L//AF5thUAgmUKlQAAAABJRU5ErkJggg==",
  "Daily Mail": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAdVBMVEUATbMASbIASrIAQK8APq8AQrAAOq5ggcYiWrfI0upIcL8AQ7DX3/CywOH///+Xq9gANKyMo9QAR7F6lM7z9vtoh8jt8fi+zOeitdynuN0UU7Xj6PR/mM9Ba75Lc8EAMKuEn9NZfMS6x+QAN60AJqkALKovYbodlZPtAAAA4UlEQVR4AcWSVRbDIBAAN2SJC1aP2/2PWKzefncisG8WB/5NQMyfhAE8QZCafxQnuqRJmuHD5VCUmGOSVlUdMV5VVRmKm5M6VLuyMuyLyhK7rknmwkNqi6NLOlGwiPPFhA22VnZR8SSB9EqHw5kpI8doepYQOTlb2bGvklSaaRgPn7JBP9Gi/JRyqBxk+iK94/0X2Yjl92wbN9vTu5ytTPqnliV6R6DS1KIxhZKrkSogTgrldpX7GdlpF7emWJuoRVGanBD1XqcUblCY5BzorC1tESA88fn5LlABT1eE5vAvrq8pFy4j7o9pAAAAAElFTkSuQmCC"
}
