import { Component, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Action } from 'src/app/models/chat';

@Component({
  selector: 'app-text-actions',
  templateUrl: './text-actions.component.html',
  styleUrl: './text-actions.component.scss',
  standalone: true,
  imports: [TranslateModule],
})
export class TextActionsComponent {
  readonly newAction = output<Action>();
  readonly actions: { key: Action; label: string }[] = [
    { key: 'write_better', label: '✍🏻 Write better' },
    { key: 'synthesize', label: '⏱️ Synthesize' },
    { key: 'grammar_check', label: '✅ Grammar check' },
    { key: 'simplify', label: '👶🏻 Simplify' },
  ];
}
