import { Component, input, model } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-panel-item',
  templateUrl: './panel-item.component.html',
  styleUrl: './panel-item.component.scss',
  standalone: true,
  imports: [CheckboxModule, FormsModule, ReactiveFormsModule, SvgIconComponent],
})
export class PanelItemComponent {
  readonly label = input.required<string>();
  readonly id = input.required<string>();
  readonly isSelected = model<boolean>(false);
}
