/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, InjectionToken, inject } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import {
  Event,
  EventType,
  Room,
  SenderType,
  SetRoomUserDetailsRequest,
} from 'src/app/api/conversation-backend-connector/models';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  of,
  scan,
  startWith,
  tap,
  timer,
  zipWith,
} from 'rxjs';

import { AssistantResponsePhase, User } from '../models/chat';
import { RealTimeConnection } from './realtime';
import { RoomsApi } from '../api/conversation-backend-connector/services';

const options: IHttpConnectionOptions = {
  /**
   * The transport has been forced to use WebSockets since other methods are not supported at all.
   * Moreover, this configuration allows us to skip the negotiation step and directly connect to the server.
   * As a result, the deploy environments do not need any kind of stickyness.
   */
  transport: signalR.HttpTransportType.WebSockets,
  skipNegotiation: true,
};

@Injectable({ providedIn: 'root' })
export class ChatService {
  private readonly roomService = inject(RoomsApi);
  private readonly connection = this.buildConnection(`/api-conversation-backend/events`);

  /*
  Current room where the conversation is happening,
  the room is created in `*-settings` components but the messages are
  displayed in `user-chat.component.ts`
  */
  readonly currentRoom$ = new BehaviorSubject<Room | undefined>(undefined);
  get currentRoom() {
    return this.currentRoom$.value;
  }
  set currentRoom(value: Room | undefined) {
    this.currentRoom$.next(value);
  }

  /*
  Current context of the chat,
  since the context is single and to expand it progressively we have to
  append the new data to the old one
  */
  readonly currentContext$ = new BehaviorSubject<string | undefined>(undefined);
  get currentContext() {
    return this.currentContext$.value;
  }
  set currentContext(value: string | undefined) {
    this.currentContext$.next(value);
  }

  /* Actual messages of the chat */
  readonly messages$ = this.connection.on<[Event]>('SendEvent');

  /* Flavour text displayed when a user send a message and the Assistant is "processing" */
  readonly phaseMessage$ = of([
    AssistantResponsePhase.Analyzing,
    AssistantResponsePhase.Searching,
    AssistantResponsePhase.Synthesizing,
    AssistantResponsePhase.Writing,
    AssistantResponsePhase.Hung,
  ]).pipe(
    mergeMap((arr) => arr),
    // Here you can set the time between the processing flavour texts
    zipWith(timer(600, Math.floor(Math.random() * (3500 - 1000 + 1) + 800))),
  );

  async establishConnection() {
    await this.connection.start();
  }

  async createRoom() {
    const room = await firstValueFrom(this.roomService.createRoom({ body: {} }));

    this.currentRoom = room;

    await this.connection.invoke('subscribe', { roomId: room.id });
    return room;
  }

  observeRoom(room: Room) {
    const subscribe$ = defer(() => this.connection.invoke('Subscribe', { roomId: room.id }));
    const msg$ = this.connection.on<[Event]>('SendEvent').pipe(
      map(([msg]) => msg),
      filter((msg) => msg.roomId === room.id),
      scan((acc, value) => acc.set(value.id, value), new Map<string, Event>()),
      map((messages) => [...messages.values()].sort((a, b) => a.createdAt.localeCompare(b.createdAt))),
      startWith([] as Event[]),
    );

    return combineLatest([msg$, subscribe$]).pipe(map(([msgs]) => msgs));
  }

  async closeCurrentRoom() {
    await firstValueFrom(this.roomService.closeRoom({ roomId: this.currentRoom?.id ?? '' }));
  }

  async postMessage(content: string, roomId: string, type?: EventType, senderType?: SenderType, image?: string) {
    const newMsg = await firstValueFrom(
      this.roomService.postEvent({
        roomId,
        body: {
          type: type ?? EventType.Message,
          senderType: senderType ?? SenderType.User,
          content,
          image,
        },
      }),
    );

    return newMsg;
  }

  async setUser(roomId: string, user: User) {
    await firstValueFrom(
      this.roomService.setRoomUserDetails({
        roomId,
        body: {
          displayName: user.username,
          emailAddress: `${user.username}@rationaleai.com`,
          firstName: user.username.split(' ').at(0),
          lastName: user.username.split(' ').at(1) ?? 'Not provided',
        },
      }),
    );
  }

  async putUser(body: SetRoomUserDetailsRequest, roomId?: string) {
    if (roomId) {
      await firstValueFrom(
        this.roomService.setRoomUserDetails({
          roomId,
          body,
        }),
      );
      return;
    }

    const { id } = await firstValueFrom(this.roomService.createRoom({ body: {} }));
    await firstValueFrom(
      this.roomService.setRoomUserDetails({
        roomId: id,
        body,
      }),
    );
  }
  private buildConnection(url: string): RealTimeConnection {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(signalR.LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    return new RealTimeConnection(connection);
  }
}
