import * as i0 from '@angular/core';
import { inject, forwardRef, Injectable, Inject, InjectionToken, makeEnvironmentProviders, ENVIRONMENT_INITIALIZER, Component, ChangeDetectionStrategy, Input, Pipe } from '@angular/core';
import { DOCUMENT } from '@angular/common';
class SvgIcon {
  constructor(content) {
    this.content = content;
    this.init = false;
  }
}
class SvgIconRegistry {
  constructor(config) {
    this.svgMap = new Map();
    this.document = inject(DOCUMENT);
    if (config?.icons) {
      this.register(config.icons);
    }
    if (config?.missingIconFallback) {
      this.register(config.missingIconFallback);
    }
  }
  getAll() {
    return this.svgMap;
  }
  get(key, config = {}) {
    const icon = key && this.svgMap.get(key);
    if (!icon) {
      return undefined;
    }
    if (!icon.init) {
      const svg = this.toElement(icon.content);
      svg.setAttribute('fit', '');
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');
      svg.setAttribute('preserveAspectRatio', config.preserveAspectRatio ?? 'xMidYMid meet');
      svg.setAttribute('focusable', 'false');
      icon.content = svg.outerHTML;
      icon.init = true;
    }
    if (config.asDataUrl) {
      const svg = this.toElement(icon.content).outerHTML;
      return `data:image/svg+xml;base64,${btoa(svg)}`;
    }
    return icon.content;
  }
  register(icons) {
    for (const {
      name,
      data
    } of Array.isArray(icons) ? icons : [icons]) {
      if (!this.svgMap.has(name)) {
        this.svgMap.set(name, new SvgIcon(data));
      }
    }
  }
  getSvgElement(name) {
    const content = this.get(name);
    if (!content) {
      return undefined;
    }
    const div = this.document.createElement('div');
    div.innerHTML = content;
    return div.querySelector('svg');
  }
  toElement(content) {
    const div = this.document.createElement('div');
    div.innerHTML = content;
    return div.querySelector('svg');
  }
}
SvgIconRegistry.ɵfac = function SvgIconRegistry_Factory(t) {
  return new (t || SvgIconRegistry)(i0.ɵɵinject(forwardRef(() => SVG_ICONS_CONFIG)));
};
SvgIconRegistry.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SvgIconRegistry,
  factory: SvgIconRegistry.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SvgIconRegistry, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [forwardRef(() => SVG_ICONS_CONFIG)]
      }]
    }];
  }, null);
})();
const SVG_ICONS_CONFIG = new InjectionToken('SVG_ICONS_CONFIG', {
  providedIn: 'root',
  factory() {
    return {};
  }
});
const SVG_ICONS = new InjectionToken('SVG_ICONS');
const SVG_MISSING_ICON_FALLBACK = new InjectionToken('SVG_MISSING_ICON_FALLBACK', {
  providedIn: 'root',
  factory() {
    return undefined;
  }
});
function provideSvgIcons(icons) {
  return makeEnvironmentProviders([{
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue() {
      inject(SvgIconRegistry).register(icons);
    }
  }]);
}
function provideSvgIconsConfig(config) {
  return makeEnvironmentProviders([{
    provide: SVG_ICONS_CONFIG,
    useValue: config
  }]);
}
class SvgIconComponent {
  constructor(host, registry, config) {
    this.host = host;
    this.registry = registry;
    this.config = config;
    this.noShrink = false;
    this.init = false;
    this.mergedConfig = this.createConfig();
  }
  get element() {
    return this.host.nativeElement;
  }
  ngOnChanges(changes) {
    if (changes.key) {
      this.setIcon(this.key);
    }
    if (changes.size?.currentValue) {
      this.setIconSize(this.mergedConfig.sizes[this.size]);
    }
    if (changes.fontSize?.currentValue) {
      this.setIconSize(coerceCssPixelValue(this.fontSize));
    }
    // If on the first change no size was passed, set the default size
    if (!this.init && !changes.size?.currentValue && !changes.fontSize?.currentValue) {
      this.setIconSize(this.mergedConfig.sizes[this.mergedConfig.defaultSize || 'md']);
    }
    if (changes.width?.currentValue) {
      this.element.style.width = `var(--svg-icon-width, ${coerceCssPixelValue(this.width)})`;
    }
    if (changes.height?.currentValue) {
      this.element.style.height = `var(--svg-icon-height, ${coerceCssPixelValue(this.height)})`;
    }
    if (changes.color?.currentValue) {
      this.element.style.color = `var(--svg-icon-color, ${this.color})`;
    }
    this.init = true;
  }
  createConfig() {
    const defaults = {
      sizes: {
        xs: '0.5rem',
        sm: '0.75rem',
        md: `1rem`,
        lg: '1.5rem',
        xl: '2rem',
        xxl: '2.5rem'
      }
    };
    const mergedConfig = {
      ...defaults,
      ...this.config
    };
    mergedConfig.sizes = Object.entries({
      ...defaults.sizes,
      ...mergedConfig.sizes
    }).reduce((acc, [key, value]) => {
      acc[key] = `var(--svg-icon-font-size-${key}, ${value})`;
      return acc;
    }, {});
    return mergedConfig;
  }
  setIconSize(size) {
    this.element.style.fontSize = size;
    if (this.noShrink) {
      this.element.style.minWidth = size;
    }
  }
  setIcon(name) {
    const config = {
      preserveAspectRatio: this.preserveAspectRatio
    };
    const icon = this.registry.get(name, config) ?? this.registry.get(this.fallback ?? this.config.missingIconFallback?.name, config);
    if (icon) {
      this.element.setAttribute('aria-label', `${name}-icon`);
      if (this.lastKey) {
        this.element.classList.remove(getIconClassName(this.lastKey));
      }
      this.lastKey = name;
      if (name) {
        this.element.classList.add(getIconClassName(name));
      }
      this.element.innerHTML = icon;
    }
  }
}
SvgIconComponent.ɵfac = function SvgIconComponent_Factory(t) {
  return new (t || SvgIconComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(SvgIconRegistry), i0.ɵɵdirectiveInject(SVG_ICONS_CONFIG));
};
SvgIconComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SvgIconComponent,
  selectors: [["svg-icon"]],
  hostAttrs: ["role", "img", "aria-hidden", "true"],
  inputs: {
    key: "key",
    fallback: "fallback",
    size: "size",
    width: "width",
    height: "height",
    fontSize: "fontSize",
    color: "color",
    noShrink: "noShrink",
    preserveAspectRatio: "preserveAspectRatio"
  },
  standalone: true,
  features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function SvgIconComponent_Template(rf, ctx) {},
  styles: ["[_nghost-%COMP%]{display:inline-block;fill:currentColor;width:var(--svg-icon-width, 1em);height:var(--svg-icon-height, 1em)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SvgIconComponent, [{
    type: Component,
    args: [{
      selector: 'svg-icon',
      template: '',
      standalone: true,
      host: {
        role: 'img',
        'aria-hidden': 'true'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:inline-block;fill:currentColor;width:var(--svg-icon-width, 1em);height:var(--svg-icon-height, 1em)}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: SvgIconRegistry
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [SVG_ICONS_CONFIG]
      }]
    }];
  }, {
    key: [{
      type: Input
    }],
    fallback: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    fontSize: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    noShrink: [{
      type: Input
    }],
    preserveAspectRatio: [{
      type: Input
    }]
  });
})();
function coerceCssPixelValue(value) {
  if (value == null) {
    return '';
  }
  return typeof value === 'string' ? value : `${value}px`;
}
function getIconClassName(key) {
  return `svg-icon-${key.replace(/ /g, '-')}`;
}
class SvgToDataUrlPipe {
  constructor() {
    this.registry = inject(SvgIconRegistry);
  }
  transform(key) {
    return this.registry.get(key, {
      asDataUrl: true
    });
  }
}
SvgToDataUrlPipe.ɵfac = function SvgToDataUrlPipe_Factory(t) {
  return new (t || SvgToDataUrlPipe)();
};
SvgToDataUrlPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "svgToDataUrl",
  type: SvgToDataUrlPipe,
  pure: true,
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SvgToDataUrlPipe, [{
    type: Pipe,
    args: [{
      name: 'svgToDataUrl',
      standalone: true
    }]
  }], null, null);
})();
function injectRegisterIcons(...params) {
  inject(SvgIconRegistry).register(...params);
}

/**
 * Generated bundle index. Do not edit.
 */

export { SvgIconComponent, SvgIconRegistry, SvgToDataUrlPipe, injectRegisterIcons, provideSvgIcons, provideSvgIconsConfig };
