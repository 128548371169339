<div class="size-full max-w-full absolute">
  @if (center$ | async; as center) {
    <ngx-graph
      id="graph"
      class="!size-[inherit] flex items-center justify-center"
      [class.hasSelection]="!!selectedNode()"
      [layout]="layout"
      [zoomLevel]="0.3"
      [draggingEnabled]="false"
      [center$]="center$"
      [links]="[]"
      [nodes]="[]"
      (stateChange)="recomputeState()">
      <ng-template
        #nodeTemplate
        let-node>
        <svg:g
          class="node"
          (click)="selectNode(node)"
          (mouseenter)="onNodeHover($event, node)"
          (mouseleave)="tooltip.set(null)">
          <svg:circle
            [attr.cx]="node.dimension.width / 2"
            [attr.cy]="node.dimension.height / 2"
            [attr.r]="node.isTopic ? 18 : 12"
            [class.selected]="selectedNode()?.id === node.id"
            [attr.fill]="nodesAttrs().get(node.id)?.color"
            [attr.stroke-width]="10"></svg:circle>
        </svg:g>
      </ng-template>
      <ng-template
        #linkTemplate
        let-link>
        @if (!link.hidden) {
          <svg:path
            [attr.stroke]="EDGE"
            [id]="link.source + link.target"
            [attr.d]="link.line"
            [class.unfocused]="markUnfocusedLink(link.target, link.source)"></svg:path>
        }
      </ng-template>
    </ngx-graph>
  }

  @for (topic of defaultTopics$.value; track topic.id) {
    <app-tooltip
      class="defaultTopic cursor-pointer"
      [style.top.px]="topic.top"
      [style.left.px]="topic.left"
      [title]="topic.title ?? '-'"
      (click)="clickOnTooltip(topic.id)"></app-tooltip>
  }

  <div
    #tooltipComponent
    class="w-fit h-fit absolute"
    [ngClass]="{
      invisible: !tooltip(),
    }">
    @if (tooltip(); as tooltip) {
      <app-tooltip
        class="flex h-fit max-w-52 border rounded-lg bg-surface text-accent-dark cursor-pointer"
        [ngClass]="{
          'flex-col p-2 border-accent': tooltip.mode === 'article',
          'px-3 py-1.5 text-sm border-rest-border': tooltip.mode === 'topic',
        }"
        [title]="tooltip.title"
        [body]="tooltip.body"
        (click)="clickOnTooltip(tooltip.id)"></app-tooltip>
    }
  </div>
</div>
