import { TooltipModule } from 'primeng/tooltip';
import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Article, TopicNode } from 'src/app/api/backend-connector/models';
import * as thumbnails from '../../../assets/thumbnails.json';
import { castToKey } from 'src/app/pages/topics-list-page/topics-list-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'app-related-topics',
  templateUrl: './related-topics.component.html',
  styleUrl: './related-topics.component.scss',
  standalone: true,
  imports: [NgClass, RouterLink, SvgIconComponent, TooltipModule, TranslateModule],
})
export class RelatedTopicsComponent {
  readonly relatedTopicsMap = input.required<{ topic: TopicNode; articles: Article[] }[] | null>();

  readonly thumbnails = thumbnails;
  readonly castToKey = castToKey;
}
