import { Pipe, PipeTransform } from '@angular/core';
import { concatMap, delay, from, map, of } from 'rxjs';

const debounceText = (value: string) => {
  let text = '';
  let insideAnchorTag = false;

  return from(value).pipe(
    concatMap((char) => {
      let charDelay = text.endsWith(' ') ? Math.floor(Math.random() * 75 + 20) : Math.floor(Math.random() * 15 + 5);
      if (insideAnchorTag) {
        charDelay = 0;
      }

      return of(char).pipe(
        delay(charDelay),
        map((c) => {
          if (text.endsWith('<a')) {
            insideAnchorTag = true;
          } else if (text.endsWith('</a>')) {
            insideAnchorTag = false;
          }

          text = `${text}${c}`;

          return text === value ? `${text}` : `${text}<span class="animate-pulse">|</span>`;
        }),
      );
    }),
  );
};

@Pipe({
  name: 'typewrite',
  standalone: true,
})
export class TypeWritePipe implements PipeTransform {
  transform = debounceText;
}
