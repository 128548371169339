import { Component, OnInit, inject, model, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TextEditorComponent } from '../../components/text-editor/text-editor.component';
import { TextActionsComponent } from '../../components/text-actions/text-actions.component';
import { RoomsApi } from 'src/app/api/conversation-backend-connector/services';
import { combineLatest, defer, filter, firstValueFrom, map, scan, startWith } from 'rxjs';
import { RealTimeConnection } from 'src/app/services/realtime';
import { IHttpConnectionOptions, HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
import { Event, Room } from 'src/app/api/conversation-backend-connector/models';
import { ConversationComponent } from 'src/app/components/conversation/conversation.component';
import { Action } from 'src/app/models/chat';
import CowriteHeaderComponent from './cowrite-header/cowrite-header.component';

@Component({
  selector: 'app-cowrite-page',
  templateUrl: './cowrite-page.component.html',
  styleUrl: './cowrite-page.component.scss',
  standalone: true,
  imports: [ConversationComponent, CowriteHeaderComponent, TextActionsComponent, TextEditorComponent, TranslateModule],
})
export default class CowritePageComponent implements OnInit {
  readonly content = signal<string | undefined>(undefined);
  readonly roomService = inject(RoomsApi);

  readonly askAIMessage = model<string | undefined>();
  readonly globalAction = model<Action | undefined>();

  private readonly connection = this.buildConnection(`/api-conversation-backend/events`);

  async ngOnInit() {
    await this.connection.start();
    const room = await this.createRoom();
    this.observeRoom(room).subscribe((e) => {
      console.log(room, e);
    });
  }

  async createRoom() {
    const room = await firstValueFrom(this.roomService.createRoom({ body: {} }));

    await this.connection.invoke('subscribe', { roomId: room.id });
    return room;
  }

  observeRoom(room: Room) {
    const subscribe$ = defer(() => this.connection.invoke('Subscribe', { roomId: room.id }));
    const msg$ = this.connection.on<[Event]>('SendEvent').pipe(
      map(([msg]) => msg),
      filter((msg) => msg.roomId === room.id),
      scan((acc, value) => acc.set(value.id, value), new Map<string, Event>()),
      map((messages) => [...messages.values()].sort((a, b) => a.createdAt.localeCompare(b.createdAt))),
      startWith([] as Event[]),
    );

    return combineLatest([msg$, subscribe$]).pipe(map(([msgs]) => msgs));
  }

  private buildConnection(url: string): RealTimeConnection {
    const options: IHttpConnectionOptions = {
      /**
       * The transport has been forced to use WebSockets since other methods are not supported at all.
       * Moreover, this configuration allows us to skip the negotiation step and directly connect to the server.
       * As a result, the deploy environments do not need any kind of stickyness.
       */
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
    };

    const connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    return new RealTimeConnection(connection);
  }
}
