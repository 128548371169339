<div
  class="h-full w-full"
  leaflet
  [leafletCenter]="mapCenter"
  [leafletZoom]="zoom"
  [leafletOptions]="mapOptions">
  <div
    *ngFor="let layer of layers"
    [leafletLayer]="layer.leafletLayer"></div>
</div>
