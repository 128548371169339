import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, ContentChildren, QueryList } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LatLng, LatLngLiteral, MapOptions, tileLayer } from 'leaflet';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { MapLayer } from './map-layer';

@Component({
  selector: 'app-map-visualizer',
  templateUrl: './map-visualizer.component.html',
  standalone: true,
  imports: [AsyncPipe, LeafletModule, NgFor, NgIf, ProgressSpinnerModule],
})
export class MapVisualizerComponent {
  @ContentChildren(MapLayer) layers?: QueryList<MapLayer>;

  @Input() set center(value: LatLngLiteral) {
    this.mapCenter = new LatLng(value.lat, value.lng);
  }

  @Input() zoom = 5;

  mapOptions: MapOptions = {
    layers: [
      // OpenStreetMap base layer
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
    ],
    zoom: this.zoom,
    scrollWheelZoom: false,
  };

  mapCenter = new LatLng(0, 0);
}
